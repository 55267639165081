import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { renderToStaticMarkup } from 'react-dom/server'
import { Minus, Navigation, Plus } from 'react-feather'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { RawResult } from 'leaflet-geosearch/lib/providers/openStreetMapProvider'
import { SearchResult } from 'leaflet-geosearch/lib/providers/provider'
import L from 'leaflet'

import { DeliveryToPoint, getReverseGeocode } from '../../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { changeDeliverySum } from '../../redux/slices/shippingPaymentSlice'
import { updateOrderField } from '../../redux/slices/orderSlice'

import './styles.scss'

const provider = new OpenStreetMapProvider()

const customMarkerIcon = (iconSrc: string) =>
  L.divIcon({
    html: renderToStaticMarkup(<img src={iconSrc} alt='' />),
    className: 'custom-marker-icon',
    iconAnchor: [13, 36],
  })

const Map = ({ locationsList }: { locationsList: DeliveryToPoint[] }) => {
  const [currentMarker, setCurrentMarker] = useState(0)
  const tileLayerAttribution =
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  const tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()

  return (
    <div className='map-container'>
      <MapContainer
        style={{ height: '100%', width: '100%' }}
        center={[54.185, 45.184]}
        zoom={10}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer attribution={tileLayerAttribution} url={tileLayerUrl} />
        {locationsList.map((location, index) => (
          <Marker
            key={location.Code}
            position={[location.Location.latitude, location.Location.longitude]}
            icon={customMarkerIcon(
              styles[0]?.TextIDsRepository.assets.mapMarker
            )}
            eventHandlers={{
              click: () => {
                setCurrentMarker(index)
                dispatch(
                  changeDeliverySum({ deliverySum: location.DeliverySum })
                )
                dispatch(updateOrderField({ DeliveryAddress: null }))
                dispatch(
                  updateOrderField({ DeliverySum: location.DeliverySum })
                )
                dispatch(
                  updateOrderField({
                    DeliveryOptions: {
                      DeliveryID: location.DeliveryID,
                      Code: location.Code,
                      DeliveryOption: location.DeliveryOption,
                    },
                  })
                )
              },
            }}
          />
        ))}
        <Controls location={locationsList[currentMarker]} />
      </MapContainer>
    </div>
  )
}

const Controls = ({ location }: { location: DeliveryToPoint }) => {
  const [searchResults, setSearchResults] = useState<SearchResult<RawResult>[]>(
    []
  )
  const [searchDefaultValue, setSearchDefaultValue] = useState('')
  const profileData = useAppSelector((state) => state.profileSlice)
  const { deliverySumToAddress } = useAppSelector(
    (state) => state.shippingPayment
  )
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()
  const map = useMap()
  let marker: any

  useEffect(() => {
    map.on('click', (event) => {
      getReverseGeocode(event.latlng.lat, event.latlng.lng).then((data) => {
        if (marker) {
          map.removeLayer(marker)
        }

        marker = new L.Marker(event.latlng, {
          icon: customMarkerIcon(styles[0]?.TextIDsRepository.assets.mapMarker),
        })
        map.addLayer(marker)
        setSearchDefaultValue(data.display_name)

        dispatch(updateOrderField({ DeliveryAddress: data.display_name }))
      })
    })
  }, [])

  useEffect(() => {
    if (location) {
      map.flyTo(
        {
          lat: location.Location.latitude,
          lng: location.Location.longitude,
        },
        18
      )
      setSearchDefaultValue(location.AddressFull)
      dispatch(changeDeliverySum({ deliverySum: location.DeliverySum }))
    } else {
      setSearchDefaultValue(profileData.customerCityFullName)
      dispatch(changeDeliverySum({ deliverySum: deliverySumToAddress }))
    }
  }, [location])

  const handleZoomIn = () => {
    map.setZoom(map.getZoom() + 1)
  }

  const handleZoomOut = () => {
    map.setZoom(map.getZoom() - 1)
  }

  const handleCurrentLocation = () => {
    map.locate().on('locationfound', (event) => {
      map.flyTo(event.latlng, 18)
    })
  }

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const results = await provider.search({ query: event.target.value })
    setSearchResults(results)
  }

  return (
    <>
      <button onClick={handleZoomIn} className='map-container__zoom-in'>
        <Plus />
      </button>
      <button onClick={handleZoomOut} className='map-container__zoom-out'>
        <Minus />
      </button>
      <button
        onClick={handleCurrentLocation}
        className='map-container__location'
      >
        <Navigation />
      </button>
      <div className='map-container__search-container'>
        <input
          className='map-container__search-container__search'
          onChange={handleSearch}
          defaultValue={searchDefaultValue}
          readOnly
        />
        {searchResults.slice(0, 3).map((result) => (
          <div
            key={result.raw.place_id}
            className='map-container__search-container__search-result'
          >
            {result.label}
          </div>
        ))}
      </div>
    </>
  )
}

export default Map
