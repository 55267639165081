import axios, { AxiosError, AxiosResponse } from 'axios'

import { PartnerInterfaceType } from '../redux/slices/partnerInterfaceSlice'
import { Supplement } from '../redux/slices/supplementsSlice'
import { SupplementInfo } from '../redux/slices/modalSupplementInfoSlice'
import { CourseItemToServer } from '../redux/slices/modalSupplementSlice'
import { CoursePriceType } from '../enums/course'
import { ApiRoutes, ApiStatuses } from '../enums/apiRoutes'
import { RegisterFormFields } from '../components/Register/Register'
import { Order } from '../redux/slices/orderSlice'
import {
  CustomerEventActions,
  CustomerEventResults,
  EventMethodsName,
  EventType,
} from '../enums/customerEventActions'
import { SurveyEntity } from '../redux/slices/surveySlice'
import {
  ClientEventState,
  CustomerEventsResponse,
  EventComment,
  EventConsultation,
  EventFile,
  EventFiles,
  EventSurveys,
} from '../redux/slices/clientEventSlice'
import {
  AnalysisEntity,
  AnalysisGroup,
  AnalysisSubGroup,
} from '../redux/slices/analysisSlice'
import { SpecialistEntity } from '../redux/slices/specialistsSlice'

type SupplementsResponse = {
  SupplementsList: Supplement[]
}

type PartnerInterfaceResponse = {
  PartnerInterface: PartnerInterfaceType[]
}

type SupplementResponse = {
  Supplements: SupplementInfo[]
}

export type SupplementCourseItem = {
  Article: string
  CurrencyID: number
  SupplementQuantity: number
  SupplementPrice: number
  ReceptionTime: string
  ReceptionDay: string
  ReceptionDuration: number
  OrderQuantity: number
}

type SupplementCourseService = {
  Article: string
  ServiceQuantity: number
  ServicePrice: number
  CurrencyID: number
  PriceType: string
}

type ChangeSupplementCourseRequest = {
  CustomerID: string
  ReceptionDuration: number
  Cookie: string
  items: CourseItemToServer[]
  SupplementCourseID?: number | null
}

export type SmallSupplementCourseType = {
  SupplementCourseID: number
  SmallSupplementCourse: string[]
}

export type SupplementCourseResponseType = {
  SupplementCourse: {
    SupplementCourseID: number
    ExecutorID: string
    ExecutorStorageID: string
    Cookie: string
    CourseDuration: number
    SupplementCourseItems: SupplementCourseItem[]
    SupplementCourseServices: SupplementCourseService[]
  }
}

export type CreateCourseResponseType = {
  SupplementCourseID: number
  SupplementCourseCost: number
  ExecutorID: string
  ExecutorStorageID: string
  Cookie: string
  CourseDuration: number
  SupplementCourseServices: {
    id: number
    ShoppingCartID: number
    Article: string
    ServiceQuantity: number
    ServicePrice: number
    CurrencyID: number
    PriceType: CoursePriceType
  }[]
  ShoppingCartsTypes: [
    {
      id: number
      ShoppingCartId: number
      BioAdds: string
      Compounds: string
      SupplementForms: string
      VariablePackage: number
    }
  ]
}

export type CustomerCountryResponseType = {
  CustomerCountry: {
    CallingCode: string
    CountryFlag: string
  }
}

export type CustomerValidatePhoneResponseType = {
  CustomerValidatePhone: {
    CustomerID: string | null
    NutritionistID: string | null
    Success: string
  }
}

export type ValidateEventResponseType = {
  ValidateEvent: {
    Code: string
    Success: string
  }
}

export type CreateCustomerResponseType = {
  CustomerCreate: {
    CustomerID: string
  }
}

export type CustomerDataResponseType = {
  CustomerData: {
    CustomerID?: null
    CustomerSurname: string
    CustomerName: string
    CustomerPatronymic: string
    CustomerCityID: string
    CustomerCityFullName: string
    CustomerDateOfBirth: string
    CustomerEmail: string
    CustomerPhone: number
    Nutritionist: boolean
    EarningID: number
  }
}

export type SelectCustomerCityItem = {
  CustomerCityID: string
  FullName: string
}

export type SelectCustomerCityResponseType = {
  SelectCustomerCity: SelectCustomerCityItem[]
}

export type CustomerEditPhoneRequest = {
  CustomerID: string
  CustomerPhone: string
}

export type DeliveryStatusForwarderItem = {
  Status: string
  Date: string
  Time: string
}

export type OrdersListItemSmall = {
  OrderNumberPlatform: string
  DeliveryStatusForwarder: string
  OrderPrice: number
}

export type OrdersListItem = {
  OrderNumberPlatform: string
  SupplementCourseID: number
  DeliveryStatusForwarder: DeliveryStatusForwarderItem[]
  OrderDateTime: string
  DeliveryOption: string
  DeliveryAddress: string
  OrderPayment: string
  PaymentOption: string
  OrderPrice: number
  SupplementCourseItems: SupplementCourseItem[]
  SupplementCourseDuration: number
}

export type OrdersListResponse = {
  OrdersList: Array<OrdersListItem & OrdersListItemSmall>
}

export type EventCommentRequest = {
  eventId: number
  header?: string
  body?: string
}

export type EventResponse = {
  [key in EventMethodsName]: {
    Result: CustomerEventResults
    EventID?: number
    Description?: string
  }
}

export type EventConsultationRequest = {
  eventId: number
  header?: string
  complaints?: string
  diseases?: string
  allergy?: string
  information?: string
  dietHistory?: string
  diagnosis?: string
  recommendations?: string
}

export type UploadFilesRequest = {
  file: FormDataEntryValue
  header: string
}

export type UploadFilesResponse = {
  eventId: number
  date: string
  fileName: string
  header: string
  pathLink: string
  eventType: EventType.File
}

export type PaymentType = {
  ID: number
  Name: string
}

export type Location = {
  longitude: number
  latitude: number
}

export type Delivery = {
  DeliveryAgentsID: number
  DeliveryID: number
  DeliveryOption: string
  DeliveryOptionDescription: string
  DeliverySum: number
  Code: string | null
  Payment: PaymentType[]
}

export type DeliveryToPoint = Delivery & {
  Address: string
  AddressFull: string
  Comment: string
  Location: Location
}

export type DeliveryAndPayment = {
  DeliveryToAddress: Delivery[]
  DeliveryToPoint: DeliveryToPoint[]
}

export type DeliveryAndPaymentResponse = {
  DeliveryAndPayment: DeliveryAndPayment
}

export type SurveyResponse = {
  questions: {
    question: string
    answerType: 'int' | 'bool' | 'text'
  }[]
}

export type NutritionistClientTag = {
  TagID: number
  TagName: string
  NutritionistID: string
}

export type NutritionistClient = {
  CustomerID: string
  Name: string
  Surname: string
  Patronymic: string
  PlannedDate: string | null
  Phone: number
  DateOfBirth: string
  Age: number
  CustomerCity: string
  Tags: NutritionistClientTag[] | null
}

export type GetCustomerListResponse = {
  GetCustomerList: NutritionistClient[]
}

export type AddCustomerTagRequest = {
  NutritionistID: string
  CustomerID: string
  TagID: number
}

export type AddCustomerTagResponse = Omit<
  NutritionistClientTag,
  'NutritionistID'
>[]

export type EditSpecialistTagRequest = {
  NutritionistID: string
  TagID: number
  TagName: string | null
}

export type EditSpecialistTagResponse = {
  EditTag: {
    TagID: string
    TagName: string
  }
}

export type DeleteTagRequest = {
  NutritionistID: string
  TagID: number
}

export type DeleteTagResponse = {
  DeleteTag: {
    TagID: number
    Result: string
  }
}

export type GetTagsListResponse = NutritionistClientTag[]

export type CreateTagRequest = {
  NutritionistID: string
  TagName: string
}

export type CreateTagResponse = NutritionistClientTag[]

export type DeleteCustomersFilesRequest = {
  FileID: string
}

export type RenameCustomersFilesRequest = DeleteCustomersFilesRequest & {
  FileName: string
}

export type EditCustomersFilesResponse = {
  EditCustomersFiles:
    | {
        Action: string
        Error: string
        FileID?: string
      }
    | {
        EventID: string
        Files: EventFile[]
      }
}

export type FillSurveyRequest = {
  header: string
  answers: {
    question: string
    answer: number | boolean | string
  }[]
}

export type FillSurveyResponse = {
  date: string
  eventId: number
  eventType: EventType.Survey
  answers: {
    question: string
    answer: number | boolean | string
  }[]
}

type CustomerBankAccountKeys =
  | 'recipientName'
  | 'inn'
  | 'kpp'
  | 'accountName'
  | 'settlementAccount'
  | 'correspondentAccount'
  | 'bik'

export type CustomerBankAccountRequisites = Record<
  CustomerBankAccountKeys,
  string
>

export type AnalyzesCartSchema = {
  serviceId: number
  guid: string
}

export type AnalyzesDeleteCartSchema = {
  serviceId?: number
  guid?: string
  biomaterialId?: string
}

export type AnalyzesCardResponse = {
  serviceId: number
  nameService: string
  price: number
  biomaterialId: string
  nameBiomaterial: string
  description: string | null
  expertCashback: string | null
  analyzesGroupGuid: string
  analyzesGroupName: string
}

export type CustomerInfoResponse = {
  specializations: string[]
  description: string | null
  telegram: string | null
  instagram: string | null
  skype: string | null
  email: string | null
  vkontakte: string | null
  whatsapp: number | null
  facebook: string | null
  banner: boolean
  factOne: string
  factTwo: string
  factThree: string
}

export type CustomerUploadPhotoRequest = {
  photo: FormDataEntryValue
}

export type CustomerPhotoResponse = {
  imageName: string
  pathLink: string
}

export type SpecialistResponse = {
  customerId: string
  customerSurname: string
  customerName: string
  customerPatronymic: string
  customerCity: string
  customerInfo: Partial<CustomerInfoResponse> | null
  experience: 1
  pathLink?: string
}

const partnerApiKey = document.getElementById(
  'VITAMIN_TRADE_ROOT_BLOCK_FOR_MOUNTING_APP_WITH_UNIQUE_ID'
)?.dataset.partnerApiKey

const config = {
  headers: {
    accept: 'application/json',
    Authorization: partnerApiKey ?? process.env.REACT_APP_AUTH_PASSWORD,
  },
}

export const getData = async (baseURL: string) => {
  const response = await axios
    .get(process.env.REACT_APP_BASE_URL + baseURL, config)
    .catch((error) => ({ data: error }))

  return response.data
}

export const setData = async <T>(
  baseURL: string,
  data: T,
  formData?: boolean
) => {
  const response = await axios
    .post(process.env.REACT_APP_BASE_URL + baseURL, data, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': formData ? 'multipart/form-data' : 'application/json',
      },
    })
    .catch((error) => ({ data: error }))

  return response.data
}

export const putData = async <T>(baseURL: string, data: T) => {
  const response = await axios
    .put(process.env.REACT_APP_BASE_URL + baseURL, data, config)
    .catch((error) => ({ data: error }))

  return response.data
}

export const patchData = async <T>(baseURL: string, data: T) => {
  const response = await axios
    .patch(process.env.REACT_APP_BASE_URL + baseURL, data, config)
    .catch((error) => ({ data: error }))

  return response.data
}

export const deleteData = async <T>(baseURL: string, data: T) => {
  return await axios
    .delete(process.env.REACT_APP_BASE_URL + baseURL, { ...config, data })
    .then((response) => response.status)
    .catch((error) => error)
}

export const getSupplementsList = async (
  baseURL = ApiRoutes.SupplementsList
): Promise<SupplementsResponse> => {
  return getData(baseURL)
}

export const getPartnerInterface = async (
  baseURL = ApiRoutes.PartnerInterface
): Promise<PartnerInterfaceResponse> => {
  return getData(baseURL)
}

export const getSupplement = async (
  article: string,
  baseURL = ApiRoutes.Supplements
): Promise<SupplementResponse> => {
  return getData(baseURL + `?Article=${article}`)
}

export const getSupplementCourse = async (
  supplementCourseCookie: string,
  customerID?: string,
  baseURL = ApiRoutes.SupplementCourse
): Promise<SupplementCourseResponseType> => {
  const query = customerID
    ? `?SupplementCourseCookie=${supplementCourseCookie}&CustomerID=${customerID}`
    : `?SupplementCourseCookie=${supplementCourseCookie}`

  return getData(baseURL + query)
}

export const getCustomerCountry = async (
  baseURL = ApiRoutes.CustomerCountry
): Promise<CustomerCountryResponseType> => {
  return getData(baseURL)
}

export const getCustomerValidatePhone = async (
  phoneNumber: string,
  baseURL = ApiRoutes.CustomerValidatePhone
): Promise<CustomerValidatePhoneResponseType> => {
  return getData(baseURL + `?CustomerPhone=${phoneNumber}`)
}

export const getValidateEvent = async (
  phoneNumber: string,
  baseURL = ApiRoutes.ValidateEvent
): Promise<ValidateEventResponseType> => {
  return getData(baseURL + `?CustomerPhone=${phoneNumber}`)
}

export const getCustomerData = async (
  customerID: string,
  baseURL = ApiRoutes.CustomerData
): Promise<CustomerDataResponseType> => {
  return getData(baseURL + `?CustomerID=${customerID}`)
}

export const getSelectCustomerCity = async (
  cityName: string,
  baseURL = ApiRoutes.SelectCustomerCity
): Promise<SelectCustomerCityResponseType> => {
  return getData(baseURL + `?CityName=${cityName}`)
}

export const getOrdersList = (
  customerID: string,
  orderNumberPlatform?: string,
  baseURL = ApiRoutes.OrdersList
): Promise<OrdersListResponse> => {
  const query = orderNumberPlatform
    ? `?CustomerID=${customerID}&OrderNumberPlatform=${orderNumberPlatform}`
    : `?CustomerID=${customerID}`

  return getData(baseURL + query)
}

export const getDeliveryAndPayment = (
  supplementCourseID: number,
  baseUrl = ApiRoutes.DeliveryAndPayment
): Promise<DeliveryAndPaymentResponse> => {
  return getData(baseUrl + `?SupplementCourseID=${supplementCourseID}`)
}

export const getReverseGeocode = async (
  latitude: number,
  longitude: number
): Promise<{ display_name: string }> => {
  const formattedLatitude = latitude.toFixed(5)
  const formattedLongitude = longitude.toFixed(5)
  const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${formattedLatitude}&lon=${formattedLongitude}`

  const response = await axios.get(url)

  return response.data
}

export const getCustomerList = async (
  customerId: string,
  baseURL = ApiRoutes.GetCustomerList
): Promise<GetCustomerListResponse> => {
  return getData(baseURL + `?CustomerID=${customerId}`)
}

export const getSurvey = async (
  customerId: string,
  baseURL = ApiRoutes.GetSurvey
): Promise<SurveyEntity> => {
  return getData(baseURL + `?customerId=${customerId}`).then(
    (response: SurveyResponse) => ({
      id: Math.random().toString(),
      questionList: response.questions.map((question, index) => ({
        id: index.toString(),
        question: question.question,
        answerType: question.answerType,
        answered: false,
        value: null,
      })),
    })
  )
}

export const getTagsList = async (
  customerId: string,
  baseURL = ApiRoutes.GetTagsList
): Promise<GetTagsListResponse> => {
  return getData(baseURL + `?NutritionistID=${customerId}`)
}

export const getGroupParentAnalyzes = async (
  baseURL = ApiRoutes.GetGroupParentAnalyzes
): Promise<AnalysisGroup[]> => {
  return getData(baseURL)
}

export const getGroupChildAnalyzes = async (
  guid: string,
  baseURL = ApiRoutes.GetGroupChildAnalyzes
): Promise<AnalysisSubGroup[]> => {
  return getData(baseURL + `/${guid}`)
}

export const getAnalyzes = async (
  guid: string,
  baseURL = ApiRoutes.GetAnalyzes
): Promise<AnalysisEntity[]> => {
  return getData(baseURL + `/${guid}`)
}

export const getSearchAnalyzesName = async (
  text: string,
  baseURL = ApiRoutes.GetSearchAnalyzesName
): Promise<AnalysisEntity[]> => {
  return getData(baseURL + `?text=${text}`)
}

export const getCustomerBankAccount = async (
  customerID: string,
  baseURL = ApiRoutes.GetCustomerBankAccount
): Promise<CustomerBankAccountRequisites> => {
  return getData(baseURL + `?CustomerID=${customerID}`)
}

export const getSpecializations = async (
  baseURL = ApiRoutes.Specializations
): Promise<string[]> => {
  return getData(baseURL)
}

export const getCustomerInfo = async (
  customerID: string,
  baseURL = ApiRoutes.GetCustomerInfo
): Promise<CustomerInfoResponse> => {
  return getData(baseURL + `?customerId=${customerID}`)
}

export const getCustomerPhoto = async (
  customerId: string,
  baseURL = ApiRoutes.GetCustomerPhoto
): Promise<CustomerPhotoResponse> => {
  return getData(baseURL + `?customerId=${customerId}`)
}

export const getSpecialists = async (
  baseURL = ApiRoutes.GetSpecialists
): Promise<SpecialistResponse[]> => {
  return getData(baseURL)
}

export const changeSupplementCourse = async (
  supplementCourseCookie: string,
  supplementCourseId: number | null,
  customerID: string,
  courseReceptionDuration: number,
  data: CourseItemToServer[],
  baseURL: ApiRoutes.CreateSupplementCourse | ApiRoutes.EditSupplementCourse
) => {
  const requestObject: ChangeSupplementCourseRequest = {
    CustomerID: customerID,
    ReceptionDuration: courseReceptionDuration,
    Cookie: supplementCourseCookie,
    items: data,
  }

  if (baseURL === ApiRoutes.EditSupplementCourse) {
    requestObject.SupplementCourseID = supplementCourseId
  }

  return setData(baseURL, requestObject)
}

export const createCustomer = async (
  data: Omit<RegisterFormFields, 'CustomerCityFullName'>,
  baseURL = ApiRoutes.CustomerCreate
): Promise<CreateCustomerResponseType> => {
  return setData(baseURL, data)
}

export const customerEditData = async (
  data: Omit<RegisterFormFields, 'CustomerCityFullName' | 'CustomerPhone'>,
  baseURL = ApiRoutes.CustomerEditData
) => {
  return setData(baseURL, data)
}

export const customerEditPhone = async (
  data: CustomerEditPhoneRequest,
  baseURL = ApiRoutes.CustomerEditPhone
) => {
  return setData(baseURL, data)
}

export const createOrder = async (
  customerId: string,
  data: Order,
  baseURL = ApiRoutes.CreateOrder
) => {
  return setData(baseURL + `?customerId=${customerId}`, data)
}

export const customerCreateComment = async (
  customerId: string,
  data: Pick<EventComment, 'header' | 'body'>,
  baseURL = ApiRoutes.CustomerCreateComment
): Promise<EventComment> => {
  return setData(baseURL + `?customerId=${customerId}`, data)
}

export const customerUpdateComment = async (
  customerId: string,
  data: EventCommentRequest,
  baseURL = ApiRoutes.CustomerUpdateComment
): Promise<EventComment> => {
  return patchData(baseURL + `?customerId=${customerId}`, data)
}

export const customerCreateConsultation = async (
  customerId: string,
  data: Omit<EventConsultation, 'eventId' | 'eventType' | 'date'>,
  baseURL = ApiRoutes.CustomerCreateConsultation
): Promise<EventConsultation> => {
  return setData(baseURL + `?customerId=${customerId}`, data)
}

export const customerUpdateConsultation = async (
  customerId: string,
  data: EventConsultationRequest,
  baseURL = ApiRoutes.CustomerUpdateConsultation
): Promise<EventConsultation> => {
  return patchData(baseURL + `?customerId=${customerId}`, data)
}

export const customerUpdateSurvey = async (
  customerId: string,
  data: Pick<EventSurveys, 'eventId' | 'header'>,
  baseURL = ApiRoutes.CustomerUpdateSurvey
): Promise<EventSurveys> => {
  return patchData(baseURL + `?customerId=${customerId}`, data)
}

export const customerUpdateFile = async (
  customerId: string,
  data: Pick<EventFiles, 'eventId' | 'header'>,
  baseURL = ApiRoutes.CustomerUpdateFile
): Promise<EventSurveys> => {
  return patchData(baseURL + `?customerId=${customerId}`, data)
}

export const uploadCustomersFiles = async (
  customerId: string,
  data: FormData,
  baseURL = ApiRoutes.UploadCustomersFiles
): Promise<UploadFilesResponse> => {
  return setData(baseURL + `?customerId=${customerId}`, data, true)
}

export const customerDeleteEvent = async (
  customerId: string,
  data: { eventId: number },
  baseURL = ApiRoutes.DeleteEvent
): Promise<{ status: number }> => {
  return deleteData(baseURL + `?customerId=${customerId}`, data).then(
    (response) => {
      if (response instanceof AxiosError && response.response?.status) {
        return { status: response.response.status }
      }

      return { status: response }
    }
  )
}

export const deleteFileEvent = async (
  customerId: string,
  data: Pick<EventFiles, 'eventId'>,
  baseURL = ApiRoutes.DeleteFileEvent
): Promise<{ status: number }> => {
  return deleteData(baseURL + `?customerId=${customerId}`, data).then(
    (response) => {
      if (response instanceof AxiosError && response.response?.status) {
        return { status: response.response.status }
      }

      return { status: response }
    }
  )
}

export const addCustomerTag = async (
  data: AddCustomerTagRequest,
  baseURL = ApiRoutes.AddCustomerTag
): Promise<AddCustomerTagResponse> => {
  return setData(baseURL, data)
}

export const editSpecialistTag = async (
  data: EditSpecialistTagRequest,
  baseURL = ApiRoutes.EditTag
): Promise<EditSpecialistTagResponse> => {
  return putData(baseURL, data)
}

export const deleteSpecialistTag = async (
  data: DeleteTagRequest,
  baseURL = ApiRoutes.DeleteTag
): Promise<DeleteTagResponse> => {
  return deleteData(baseURL, data).then((response) => response.data)
}

export const deleteCustomerTag = async (
  data: AddCustomerTagRequest,
  baseURL = ApiRoutes.DeleteCustomerTag
): Promise<{ status: AxiosResponse['status'] }> => {
  return deleteData(baseURL, data).then((response) => ({
    status: response.status,
  }))
}

export const createTag = async (
  data: CreateTagRequest,
  baseURL = ApiRoutes.CreateTag
): Promise<CreateTagResponse> => {
  return setData(baseURL, data)
}

export const getCustomerEvents = async (
  customerId: string,
  baseURL = ApiRoutes.GetCustomerEvents
): Promise<ClientEventState> => {
  return getData(baseURL + `?customerId=${customerId}`).then((response) => ({
    events: response,
  }))
}

export const deleteCustomersFiles = async (
  data: DeleteCustomersFilesRequest,
  baseURL = ApiRoutes.EditCustomersFiles
): Promise<EditCustomersFilesResponse> => {
  return setData(baseURL + `?Action=delete`, data)
}

export const renameCustomersFiles = async (
  data: RenameCustomersFilesRequest,
  baseURL = ApiRoutes.EditCustomersFiles
): Promise<EditCustomersFilesResponse> => {
  return setData(baseURL + `?Action=rename`, data)
}

export const fillSurvey = async (
  customerId: string,
  data: FillSurveyRequest,
  baseURL = ApiRoutes.FillSurvey
): Promise<FillSurveyResponse> => {
  return setData(baseURL + `?customerId=${customerId}`, data)
}

export const customerCreateBankAccount = async (
  customerID: string,
  data: CustomerBankAccountRequisites,
  baseURL = ApiRoutes.CustomerCreateBankAccount
): Promise<CustomerBankAccountRequisites> => {
  return setData(baseURL + `?CustomerID=${customerID}`, data)
}

export const customerUpdateBankAccount = async (
  customerID: string,
  data: CustomerBankAccountRequisites,
  baseURL = ApiRoutes.CustomerUpdateBankAccount
): Promise<CustomerBankAccountRequisites> => {
  return putData(baseURL + `?CustomerID=${customerID}`, data)
}

export const analyzesAddCart = async (
  customerID: string,
  data: AnalyzesCartSchema,
  baseURL = ApiRoutes.AnalyzesAddCart
): Promise<AnalyzesCardResponse> => {
  return setData(baseURL + `?customerId=${customerID}`, data)
}

export const analyzesDeleteCart = async (
  customerID: string,
  data: AnalyzesDeleteCartSchema,
  baseURL = ApiRoutes.AnalyzesDeleteCart
): Promise<{ status: AxiosResponse['status'] }> => {
  return deleteData(baseURL + `?customerId=${customerID}`, data).then(
    (response) => ({
      status: response,
    })
  )
}

export const analyzesGetCart = async (
  customerId: string,
  baseURL = ApiRoutes.AnalyzesGetCart
): Promise<AnalyzesCardResponse[]> => {
  return getData(baseURL + `?customerId=${customerId}`)
}

export const customerUpdateInfo = async (
  customerId: string,
  data: Partial<CustomerInfoResponse>,
  baseURL = ApiRoutes.CustomerUpdateInfo
): Promise<CustomerInfoResponse> => {
  return patchData(baseURL + `?customerId=${customerId}`, data)
}

export const customerAddInfo = async (
  customerId: string,
  data: Partial<CustomerInfoResponse>,
  baseURL = ApiRoutes.CustomerAddInfo
): Promise<CustomerInfoResponse> => {
  return setData(baseURL + `?customerId=${customerId}`, data)
}

export const customerUploadPhoto = async (
  customerId: string,
  data: CustomerUploadPhotoRequest,
  baseURL = ApiRoutes.CustomerUploadPhoto
): Promise<CustomerPhotoResponse> => {
  return setData(baseURL + `?CustomerID=${customerId}`, data, true)
}
