import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { AxiosError } from 'axios'

import {
  getCustomerData,
  getPartnerInterface,
  getSupplementCourse,
  getSupplementsList,
  SupplementCourseResponseType,
} from './utils/axiosManager'
import { useAppDispatch, useAppSelector } from './redux/hook'
import {
  addAllSupplements,
  addPurpose,
  Supplement,
} from './redux/slices/supplementsSlice'
import { setErrorState } from './redux/slices/errorsSlice'
import { addAllPartnerInterface } from './redux/slices/partnerInterfaceSlice'
import { CookieEnum } from './enums/cookie'
import {
  addConvertedDataToCourse,
  addSupplementToCourse,
} from './redux/slices/courseSlice'
import RootRoutes from './routes/RootRoutes'
import {
  updateProfileCustomerField,
  updateProfileDataFromServer,
} from './redux/slices/profileSlice'
import { updateOrderField } from './redux/slices/orderSlice'
import {
  appendLinkToDOM,
  appendMetaDescriptionToDOM,
} from './utils/appendElementToDOM'

const App: React.FC = () => {
  const [cookies] = useCookies([
    CookieEnum.SupplementCourse,
    CookieEnum.CustomerID,
  ])
  const supplementsList = useAppSelector((state) => state.supplements.list)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const customerID = cookies.CustomerID

    initializeRequests().catch(() => dispatch(setErrorState(true)))

    if (customerID) {
      dispatch(updateProfileCustomerField({ customerID: customerID }))

      getCustomerData(customerID).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        if (data.CustomerData.CustomerName) {
          dispatch(updateProfileDataFromServer(data))
        }
      })
    }
  }, [])

  useEffect(() => {
    if (cookies.SupplementCourse && supplementsList.length) {
      getSupplementCourse(cookies.SupplementCourse, cookies.CustomerID).then(
        (data) => {
          if (data instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          if (typeof data.SupplementCourse === 'string') {
            return
          }

          setSupplementsToCourse(data)

          dispatch(addConvertedDataToCourse(data))
          dispatch(
            updateOrderField({
              SupplementCourseID: data.SupplementCourse.SupplementCourseID,
            })
          )
        }
      )
    }
  }, [supplementsList])

  const initializeRequests = async () => {
    const [interfaceResponse, supplementsResponse] = await Promise.all([
      getPartnerInterface(),
      getSupplementsList(),
    ])

    if (interfaceResponse.PartnerInterface) {
      const partnerInterface = interfaceResponse.PartnerInterface

      // ToDo: turn on
      // appendLinkToDOM(partnerInterface[0]?.CssFile)
      appendMetaDescriptionToDOM(partnerInterface[0]?.Description)
      document.title = partnerInterface[0]?.Title

      dispatch(addAllPartnerInterface(partnerInterface))
    } else if (interfaceResponse instanceof AxiosError) {
      dispatch(setErrorState(true))
      return
    }

    if (supplementsResponse.SupplementsList) {
      dispatch(addAllSupplements(supplementsResponse.SupplementsList))

      supplementsResponse.SupplementsList.forEach((supplement) => {
        supplement.Purposes.forEach((purpose) => {
          dispatch(addPurpose(purpose))
        })
      })
    } else if (supplementsResponse instanceof AxiosError) {
      dispatch(setErrorState(true))
      return
    }
  }

  const setSupplementsToCourse = (data: SupplementCourseResponseType) => {
    const supplementsInCourse: Supplement[] = []

    data.SupplementCourse.SupplementCourseItems.forEach(
      (supplementCourseItem) => {
        const currentSupplement = supplementsList.filter(
          (supplement) => supplement.Article === supplementCourseItem.Article
        )[0]
        supplementsInCourse.push(currentSupplement)
      }
    )

    supplementsInCourse.forEach((supplement) => {
      dispatch(
        addSupplementToCourse({
          supplement,
          recipes: [],
          courseId: data.SupplementCourse.SupplementCourseID,
        })
      )
    })
  }

  return <RootRoutes />
}

export default App
