type ReturnFileObject = {
  contentType: string
  fileData: string
}

export const fileToBase64 = (file: Blob): Promise<ReturnFileObject> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const contentType = reader.result.substring(
          reader.result.indexOf(':') + 1,
          reader.result.indexOf(';')
        )
        const fileData = reader.result.split(',')[1]

        return resolve({
          contentType,
          fileData,
        })
      }
    }

    reader.onerror = reject
  })
