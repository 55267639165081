import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  DeliveryAndPayment,
  DeliveryToPoint,
  getDeliveryAndPayment,
  PaymentType,
} from '../../utils/axiosManager'
import {
  changeDeliverySum,
  changeDeliverySumToAddress,
} from '../../redux/slices/shippingPaymentSlice'
import { updateOrderField } from '../../redux/slices/orderSlice'
import { setErrorState } from '../../redux/slices/errorsSlice'
import CoursePanelDropdownMenu from '../CoursePanel/CoursePanelDropdownMenu'
import Delivery from '../Delivery'
import Payment from '../Payment'

import './styles.scss'

export type DeliveryOptionType = 'courier' | 'point' | 'mailStation' | ''

export type HeaderOptionsText = {
  delivery: string
  payment: string
}

type ShippingPaymentBodyProps = {
  setCurrentPaymentMethodText: (
    value: (prevState: HeaderOptionsText) => HeaderOptionsText
  ) => void
}

const ShippingPayment: React.FC = () => {
  const [currentPaymentMethodText, setCurrentPaymentMethodText] =
    useState<HeaderOptionsText>({
      delivery: '',
      payment: '',
    })

  return (
    <CoursePanelDropdownMenu
      Header={ShippingPaymentHeader}
      Body={ShippingPaymentBody}
      classNames='shipping-payment-container'
      headerProps={{ currentPaymentMethodText }}
      bodyProps={{ currentPaymentMethodText, setCurrentPaymentMethodText }}
      checkAuth={true}
    />
  )
}

const ShippingPaymentHeader = ({
  currentPaymentMethodText,
}: {
  currentPaymentMethodText: HeaderOptionsText
}) => {
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const isComma =
    currentPaymentMethodText.delivery.length &&
    currentPaymentMethodText.payment.length

  return (
    <>
      <span className='shipping-payment-main-text'>
        {styles[0]?.TextIDsRepository.myCourseDeliveryNPay}
      </span>

      <span className='shipping-payment-second-text'>
        {currentPaymentMethodText.delivery}
        {!!isComma && ', '}
        {currentPaymentMethodText.payment}
      </span>
    </>
  )
}

const ShippingPaymentBody: React.FC<ShippingPaymentBodyProps> = ({
  setCurrentPaymentMethodText,
}) => {
  const [deliveryAndPayment, setDeliveryAndPayment] =
    useState<DeliveryAndPayment>({
      DeliveryToAddress: [],
      DeliveryToPoint: [],
    })
  const [locationsList, setLocationsList] = useState<DeliveryToPoint[]>([])
  const [paymentOptions, setPaymentOptions] = useState<PaymentType[]>([])
  const [activeDeliveryOption, setActiveDeliveryOption] =
    useState<DeliveryOptionType>('')
  const { course } = useAppSelector((state) => state.course)
  const { deliverySumToAddress } = useAppSelector(
    (state) => state.shippingPayment
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (course.id) {
      getDeliveryAndPayment(course.id).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        setDeliveryAndPayment(data.DeliveryAndPayment)
        setPaymentOptions(data.DeliveryAndPayment.DeliveryToAddress[0].Payment)
        dispatch(
          changeDeliverySumToAddress({
            deliverySumToAddress:
              data.DeliveryAndPayment.DeliveryToAddress[0].DeliverySum,
          })
        )
      })
    }
  }, [])

  useEffect(() => {
    if (
      !deliveryAndPayment.DeliveryToPoint.length ||
      !deliveryAndPayment.DeliveryToAddress.length
    ) {
      return
    }

    if (activeDeliveryOption === 'courier') {
      const deliveryToAddress = deliveryAndPayment.DeliveryToAddress[0]
      setPaymentOptions(deliveryToAddress.Payment)
      setLocationsList([])
      dispatch(changeDeliverySum({ deliverySum: deliverySumToAddress }))

      dispatch(
        updateOrderField({
          DeliveryOptions: {
            DeliveryID: deliveryToAddress.DeliveryID,
            Code: deliveryToAddress.Code,
            DeliveryOption: deliveryToAddress.DeliveryOption,
          },
        })
      )

      dispatch(updateOrderField({ DeliverySum: deliveryToAddress.DeliverySum }))
      return
    }

    if (activeDeliveryOption === 'point') {
      const locationsList = deliveryAndPayment.DeliveryToPoint.filter(
        (point) => point.DeliveryOption === 'PVZ'
      )
      setPaymentOptions(locationsList[0].Payment)
      setLocationsList(locationsList)

      dispatch(updateOrderField({ DeliveryAddress: null }))
      dispatch(updateOrderField({ DeliverySum: locationsList[0].DeliverySum }))
      dispatch(
        updateOrderField({
          DeliveryOptions: {
            DeliveryID: locationsList[0].DeliveryID,
            Code: locationsList[0].Code,
            DeliveryOption: locationsList[0].DeliveryOption,
          },
        })
      )
      return
    }

    if (activeDeliveryOption === 'mailStation') {
      const locationsList = deliveryAndPayment.DeliveryToPoint.filter(
        (point) => point.DeliveryOption === 'POSTAMAT'
      )
      setPaymentOptions(locationsList[0].Payment)
      setLocationsList(locationsList)

      dispatch(updateOrderField({ DeliveryAddress: null }))
      dispatch(updateOrderField({ DeliverySum: locationsList[0].DeliverySum }))
      dispatch(
        updateOrderField({
          DeliveryOptions: {
            DeliveryID: locationsList[0].DeliveryID,
            Code: locationsList[0].Code,
            DeliveryOption: locationsList[0].DeliveryOption,
          },
        })
      )
      return
    }
  }, [activeDeliveryOption])

  const handleDeliveryOption = (option: DeliveryOptionType) => {
    setActiveDeliveryOption(option)
  }

  return (
    <>
      <Delivery
        activeDeliveryOption={activeDeliveryOption}
        handleDeliveryOption={handleDeliveryOption}
        locationsList={locationsList}
        setCurrentPaymentMethodText={setCurrentPaymentMethodText}
      />
      <Payment
        paymentOptions={paymentOptions}
        setCurrentPaymentMethodText={setCurrentPaymentMethodText}
      />
    </>
  )
}

export default ShippingPayment
