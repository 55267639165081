import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Order = {
  SupplementCourseID: number
  PaymentOptionID: number
  OrderPaid: boolean
  DeliveryAddress: string | null
  DeliverySum: number
  DeliveryComment: string
  DeliveryOptions: {
    DeliveryID: number
    Code: string | null
    DeliveryOption: string
  }
}

const initialState: Order = {
  SupplementCourseID: 0,
  PaymentOptionID: 0,
  OrderPaid: false,
  DeliveryAddress: null,
  DeliverySum: 0,
  DeliveryComment: '',
  DeliveryOptions: {
    DeliveryID: 0,
    Code: null,
    DeliveryOption: '',
  },
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateOrderField(
      state,
      action: PayloadAction<Partial<Record<keyof Order, unknown>>>
    ) {
      const [key, value] = Object.entries(action.payload).flat()

      return {
        ...state,
        [key as string]: value,
      }
    },
  },
})

export const { updateOrderField } = orderSlice.actions

export default orderSlice.reducer
