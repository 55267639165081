import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  Download,
  HelpCircle,
  Info,
  MapPin,
  Search,
} from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, LayoutGroup } from 'framer-motion'
import { AxiosError } from 'axios'

import PortalTooltip from '../UI/PortalTooltip'
import FilterControls from '../shared/FilterControls'
import AnalysisGroupCard from './AnalysisGroupCard'
import AnalysisLoad from './AnalysisLoad'
import AnalysisPanel from './AnalysisPanel'
import CoursePanel from '../CoursePanel'
import {
  addAnalyzesGroups,
  addAnalyzesList,
  addAnalyzesSubGroups,
  AnalysisEntity,
  changeCurrentGroup,
} from '../../redux/slices/analysisSlice'
import AnalysisOrderCard from './AnalysisOrderCard'
import CoursePanelDropdownMenu from '../CoursePanel/CoursePanelDropdownMenu'
import Map from '../Map'
import RadioButton from '../UI/RadioButton'
import Button from '../UI/Button'
import { RoutesEnum } from '../../enums/routes'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  analyzesGetCart,
  DeliveryToPoint,
  getGroupChildAnalyzes,
  getGroupParentAnalyzes,
  getSearchAnalyzesName,
} from '../../utils/axiosManager'
import { CookieEnum } from '../../enums/cookie'
import FileUpload from '../FileUpload'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { ModalWrapper } from '../ModalWrapper'
import { addFiles, FileItem } from '../../redux/slices/uploadFilesSlice'
import Spinner from '../Spinner'
import AnalysisEntityCard from './AnalysisEntityCard'
import { addAnalysisToOrder } from '../../redux/slices/analysisOrderSlice'
import { setErrorState } from '../../redux/slices/errorsSlice'

const AnalysisContainer = () => {
  const [currentFilter, setCurrentFilter] = useState({ type: 'pass' })
  const [currentOrderFilter, setCurrentOrderFilter] = useState({
    type: 'research',
  })
  const [locationsList] = useState<DeliveryToPoint[]>([])
  const [dragHandlerCounter, setDragHandlerCounter] = useState(0)
  const [isOpenDescriptionTable, setIsOpenDescriptionTable] = useState(false)
  const [isCreatedOrder, setIsCreatedOrder] = useState(false)
  const [isShowLoadFileScreen, setIsShowLoadFileScreen] = useState(false)
  const [isOpenAnalysisSearch, setIsOpenAnalysisSearch] = useState(false)
  const [isOpenAnalysisLocation, setIsOpenAnalysisLocation] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [locationState, setLocationState] = useState<
    { guid: string; name: string }[]
  >([])
  const uploadFiles = useAppSelector((state) => state.uploadFiles.fileList)

  const groupList = useAppSelector((state) => state.analysis.groupList)
  const subGroupList = useAppSelector((state) => state.analysis.subGroupList)
  const analysisList = useAppSelector((state) => state.analysis.analyzesList)
  const currentGroup = useAppSelector((state) => state.analysis.currentGroup)
  const analysisOrderList = useAppSelector((state) => state.analysisOrder.list)
  const analysisOrderByBiomaterial = useAppSelector(
    (state) => state.analysisOrder.orderByBiomaterial
  )
  const analysisOrderByResearch = useAppSelector(
    (state) => state.analysisOrder.orderByResearch
  )

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const customerID = useAppSelector((state) => state.profileSlice.customerID)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [cookies] = useCookies([CookieEnum.CustomerID])
  const { isMobile } = useMatchMedia()

  useEffect(() => {
    getGroupParentAnalyzes().then((response) => {
      if (response instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(addAnalyzesGroups(response))
    })

    analyzesGetCart(customerID).then((response) => {
      if (response instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      response.forEach((analysisInCart) => {
        dispatch(
          addAnalysisToOrder({
            ...analysisInCart,
            history: [
              {
                guid: analysisInCart.analyzesGroupGuid,
                name: analysisInCart.analyzesGroupName,
              },
            ],
          })
        )
      })
    })
  }, [])

  useEffect(() => {
    const debounceGetData = setTimeout(() => {
      if (searchValue.length <= 3) {
        dispatch(addAnalyzesList([]))
      }

      if (searchValue.length > 3) {
        setIsLoading(true)

        getSearchAnalyzesName(searchValue).then((response) => {
          if (response instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          dispatch(addAnalyzesList(response))
          setIsLoading(false)
        })
      }
    }, 1000)

    return () => clearTimeout(debounceGetData)
  }, [searchValue])

  const totalOrderPrice = useMemo(() => {
    return analysisOrderList.reduce((acc, item) => {
      return acc + item.price
    }, 0)
  }, [analysisOrderList])

  const biomaterialCount = useMemo(() => {
    const names: string[] = []

    analysisOrderList.forEach((item) => {
      if (!names.includes(item.biomaterialId)) {
        names.push(item.biomaterialId)
      }
    })

    return names.length
  }, [analysisOrderList])

  const dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (!cookies.CustomerID && uploadFiles.length === 2) {
      return
    }

    const tempCounter = dragHandlerCounter + 1

    setDragHandlerCounter(tempCounter)
    setIsShowLoadFileScreen(true)
  }

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setDragHandlerCounter((prevState) => {
      const tempCount = prevState - 1
      if (tempCount === 0) {
        setIsShowLoadFileScreen(false)
      }

      return tempCount
    })
  }

  const handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const files: FileItem[] = Object.values(event.target.files as FileList).map(
      (file) => ({
        file,
        fileLink: '',
        isUpload: false,
      })
    )

    dispatch(
      addFiles(
        !cookies.CustomerID
          ? [...uploadFiles, ...files].slice(0, 2)
          : [...uploadFiles, ...files]
      )
    )

    setIsShowLoadFileScreen(false)
  }

  const deleteUploadFile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fileName: string
  ) => {
    event.stopPropagation()
    const newUploadFiles = uploadFiles.filter(
      ({ file }) => file.name !== fileName
    )

    dispatch(addFiles(newUploadFiles))
  }

  const analyzesCatalogNavigateBack = () => {
    dispatch(addAnalyzesList([]))

    if (locationState.length === 1) {
      dispatch(addAnalyzesSubGroups([]))
      dispatch(
        changeCurrentGroup({
          guid: '',
          name: '',
        })
      )
      setLocationState((prevState) => prevState.slice(0, -1))

      return
    }

    setLocationState((prevState) => prevState.slice(0, -1))
    const lastLocationAddress = locationState[locationState.length - 2]

    setIsLoading(true)

    getGroupChildAnalyzes(lastLocationAddress.guid).then((response) => {
      dispatch(addAnalyzesSubGroups(response))

      dispatch(
        changeCurrentGroup({
          guid: lastLocationAddress.guid,
          name: lastLocationAddress.name,
        })
      )

      setIsLoading(false)
    })
  }

  const renderCoursePanelClosedAnalysisHeader = () => {
    return (
      <div className='analysis-page__panel__header'>
        <span className='analysis-page__panel__header__title'>
          {isMobile
            ? styles[0]?.TextIDsRepository.analysisOrderTitle
            : styles[0]?.TextIDsRepository.analysisOrderTitleShort}
        </span>

        {isMobile && (
          <span className='analysis-page__panel__header__count'>
            Анализов: {analysisOrderList.length}
          </span>
        )}
      </div>
    )
  }

  const renderCoursePanelAnalysisHeader = () => {
    return (
      <div className='analysis-page__panel__header'>
        <span className='analysis-page__panel__header__title'>
          {styles[0]?.TextIDsRepository.analysisOrderTitle}
        </span>

        <PortalTooltip
          text={styles[0]?.TextIDsRepository.analysisOrderTooltip}
          classNames='analysis-page__panel__header__tooltip'
        >
          <div className='analysis-page__header__help'>
            <HelpCircle />
          </div>
        </PortalTooltip>
      </div>
    )
  }

  const renderCreatedOrderHeader = () => {
    return (
      <div className='analysis-page__panel__header'>
        <span className='analysis-page__panel__header__title'>
          Ваш заказ №24-06-22-1555 оформлен
        </span>

        {!isMobile && (
          <button className='analysis-page__panel__header__order-form'>
            <Download />
            <span>Бланк заказа</span>
          </button>
        )}
      </div>
    )
  }

  const renderGroupsList = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (analysisList.length > 0) {
      return analysisList.map((entity) => (
        <AnalysisEntityCard
          key={entity.serviceId}
          entity={entity}
          locationState={locationState}
        />
      ))
    }

    return subGroupList.length > 0
      ? subGroupList.map((group) => (
          <AnalysisGroupCard
            key={group.guid}
            group={group}
            setIsLoading={setIsLoading}
            setLocationState={setLocationState}
          />
        ))
      : groupList.map((group) => (
          <AnalysisGroupCard
            key={group.guid}
            group={group}
            setIsLoading={setIsLoading}
            setLocationState={setLocationState}
          />
        ))
  }

  return (
    <>
      <div className='analysis-page'>
        <div className='analysis-page__header'>
          <span className='analysis-page__header__title'>
            {styles[0]?.TextIDsRepository.analysisTitle}
          </span>

          <PortalTooltip
            text={styles[0]?.TextIDsRepository.analysisTitleTooltip}
            classNames='analysis-page__header__help-tooltip'
          >
            <div className='analysis-page__header__help'>
              <HelpCircle />
            </div>
          </PortalTooltip>

          {!isMobile && (
            <>
              <label
                className={clsx(
                  'clients-container__header__label',
                  'analysis-page__search'
                )}
              >
                <input
                  className='clients-container__header__search'
                  value={searchValue}
                  onChange={(event) => {
                    setSearchValue(event.target.value)
                  }}
                  placeholder='Введите название анализов...'
                />

                <div className='clients-container__header__label__search-icon'>
                  <Search />
                </div>
              </label>

              <div className='analysis-page__search-container__location'>
                <div className='analysis-page__search-container__marker-icon'>
                  <MapPin />
                </div>

                <span className='analysis-page__search-container__city'>
                  Новосибирск
                </span>

                <div className='analysis-page__search-container__city-arrow'></div>
              </div>
            </>
          )}

          {isMobile && (
            <div className='analysis-page__header__search-container-mobile'>
              <button
                onClick={() => setIsOpenAnalysisSearch(true)}
                className='analysis-page__header__search-container-mobile__control'
              >
                <Search />
              </button>

              <button
                onClick={() => setIsOpenAnalysisLocation(true)}
                className='analysis-page__header__search-container-mobile__control'
              >
                <MapPin />
              </button>

              <AnimatePresence>
                {isOpenAnalysisSearch && (
                  <ModalWrapper
                    setIsOpen={setIsOpenAnalysisSearch}
                    fullScreen={true}
                    contentClassNames='analysis-page__header__search-container-modal'
                  >
                    <div className='analysis-page__header__search-container-modal__content'>
                      <span className='analysis-page__header__search-container-modal__title'>
                        Название анализа
                      </span>

                      <input
                        className='analysis-page__header__search-container-modal__input'
                        onChange={(event) =>
                          setSearchValue(event.target.value.toLowerCase())
                        }
                      />

                      <div className='analysis-page__header__search-container-modal__found-elements'>
                        {analysisList.map((entity) => (
                          <AnalysisEntityCard
                            key={entity.serviceId}
                            entity={entity}
                            locationState={locationState}
                          />
                        ))}
                      </div>
                    </div>
                  </ModalWrapper>
                )}
              </AnimatePresence>

              <AnimatePresence>
                {isOpenAnalysisLocation && (
                  <ModalWrapper
                    setIsOpen={setIsOpenAnalysisLocation}
                    fullScreen={true}
                    contentClassNames='analysis-page__header__search-container-modal'
                  >
                    <div className='analysis-page__header__search-container-modal__content'>
                      <span className='analysis-page__header__search-container-modal__title'>
                        Местоположение
                      </span>

                      <input
                        className='analysis-page__header__search-container-modal__input'
                        onChange={(event) =>
                          setSearchValue(event.target.value.toLowerCase())
                        }
                      />
                    </div>
                  </ModalWrapper>
                )}
              </AnimatePresence>
            </div>
          )}
        </div>

        <div className='analysis-page__filter'>
          <LayoutGroup id='analysisPage'>
            <FilterControls
              buttonsList={[
                {
                  currentFilterType: 'pass',
                  title: styles[0]?.TextIDsRepository.analysisPassTitle,
                },
                {
                  currentFilterType: 'load',
                  title: styles[0]?.TextIDsRepository.analysisDownloadTitle,
                },
              ]}
              state={currentFilter}
              changeState={setCurrentFilter}
            />
          </LayoutGroup>
        </div>

        {currentFilter.type === 'pass' && (
          <div
            className={clsx('analysis-page__search-container', {
              'analysis-page__search-container__no-margin':
                (!locationState.length && isMobile) ||
                (!locationState.length && analysisOrderList.length),
            })}
          >
            {analysisOrderList.length === 0 && locationState.length === 0 && (
              <span className='analysis-page__empty-order'>
                Выберите анализы, чтобы создать свой заказ в лабораторию
              </span>
            )}
            {locationState.length > 0 && (
              <button
                onClick={analyzesCatalogNavigateBack}
                className='analysis-page__search-container__back-button'
                disabled={isLoading}
              >
                <ArrowLeft />
              </button>
            )}

            {locationState.length > 0 && currentGroup.name.length > 0 && (
              <span className='analysis-page__search-container__current-group'>
                {currentGroup.name}
              </span>
            )}
          </div>
        )}

        {currentFilter.type === 'pass' && (
          <div className='analysis-page__cards-wrapper'>
            {renderGroupsList()}
          </div>
        )}

        {currentFilter.type === 'load' && (
          <>
            <AnalysisLoad
              dragEnterHandler={dragEnterHandler}
              dragLeaveHandler={dragLeaveHandler}
              handleUploadFiles={handleUploadFiles}
              deleteUploadFile={deleteUploadFile}
            />

            <AnalysisPanel />
          </>
        )}
      </div>

      {!isCreatedOrder ? (
        <CoursePanel
          headerClosedPanel={renderCoursePanelClosedAnalysisHeader()}
          header={renderCoursePanelAnalysisHeader()}
        >
          <>
            {!analysisOrderList.length ? (
              <span className='analysis-page__empty-order'>
                Выберите анализ или комплекс анализов, чтобы собрать заказ
              </span>
            ) : (
              <FilterControls
                buttonsList={[
                  {
                    currentFilterType: 'research',
                    title: 'По исследованию',
                  },
                  {
                    currentFilterType: 'biomaterial',
                    title: 'По биоматериалу',
                  },
                ]}
                state={currentOrderFilter}
                changeState={setCurrentOrderFilter}
              />
            )}

            {currentOrderFilter.type === 'research' &&
              Object.entries(analysisOrderByResearch).map(
                (
                  research: [
                    string,
                    { nameResearch: string; list: AnalysisEntity[] }
                  ]
                ) => {
                  return (
                    <AnalysisOrderCard
                      key={research[0]}
                      groupDeleteEvent={{
                        id: research[0],
                        type: 'guid',
                      }}
                      cardTitle={research[1].nameResearch}
                      entityList={research[1].list}
                      research={true}
                    />
                  )
                }
              )}

            {currentOrderFilter.type === 'biomaterial' &&
              Object.entries(analysisOrderByBiomaterial).map(
                (
                  biomaterial: [
                    string,
                    { nameBiomaterial: string; list: AnalysisEntity[] }
                  ]
                ) => {
                  return (
                    <AnalysisOrderCard
                      key={biomaterial[0]}
                      groupDeleteEvent={{
                        id: biomaterial[0],
                        type: 'biomaterialId',
                      }}
                      cardTitle={biomaterial[1].nameBiomaterial}
                      entityList={biomaterial[1].list}
                    />
                  )
                }
              )}

            <div className='analysis-page__ordering'>
              <div className='analysis-page__ordering__header'>
                <img
                  src={styles[0]?.TextIDsRepository.assets.microscopeIcon}
                  alt=''
                  className='analysis-page__ordering__title-image'
                />
                <span className='analysis-page__ordering__title'>
                  Оформление заказа
                </span>
              </div>

              <CoursePanelDropdownMenu
                Header={
                  <span className='analysis-page__ordering__lab-address__title'>
                    Адрес лаборатории и оплата
                  </span>
                }
                Body={
                  <>
                    {locationsList && <Map locationsList={locationsList} />}

                    <div className='analysis-page__ordering__lab-address__checkbox'>
                      <RadioButton
                        key='payment-in-lab'
                        label='Оплата в лаборатории'
                        checked={true}
                        onChange={() => console.log('change payment')}
                      />
                    </div>
                  </>
                }
                classNames='analysis-page__ordering__lab-address'
              />

              <CoursePanelDropdownMenu
                Header={
                  <>
                    <div
                      className={clsx(
                        'analysis-page__ordering__total-price-container'
                      )}
                    >
                      <span className='analysis-page__ordering__lab-address__title'>
                        {`Итого к оплате: ${totalOrderPrice} ${styles[0]?.TextIDsRepository.currency}`}
                      </span>

                      <span className='analysis-page__ordering__total-price__analysis-count'>
                        Анализов: {analysisOrderList.length}
                      </span>
                    </div>

                    <Button
                      title={styles[0]?.TextIDsRepository.myCourseOrder}
                      click={() => setIsCreatedOrder(true)}
                      classNames='total-pay-order-button'
                      disabled={!analysisOrderList.length}
                    />
                  </>
                }
                Body={
                  <>
                    <div className='analysis-page__ordering__total-price__header'>
                      <span>Исследований</span>

                      <span>
                        {analysisOrderList.length} шт.
                        {!isMobile && (
                          <button
                            className='total-pay-panel__info-container__block-button'
                            onClick={() =>
                              setIsOpenDescriptionTable(
                                (prevState) => !prevState
                              )
                            }
                          >
                            {!isOpenDescriptionTable ? (
                              <ChevronDown />
                            ) : (
                              <ChevronUp />
                            )}
                          </button>
                        )}
                      </span>

                      <span>Лаборатория</span>

                      <span>Гемотест</span>
                    </div>

                    {isOpenDescriptionTable && (
                      <div
                        className={clsx(
                          'total-pay-panel__table-container',
                          'analysis-page__ordering__total-price__table'
                        )}
                      >
                        <table>
                          <thead>
                            <tr>
                              <td>
                                {styles[0].TextIDsRepository.formTableTHGoods}
                              </td>
                              <td>Биоматериал</td>
                              <td className='total-pay-panel__table-container__text-right'>
                                Сумма
                              </td>
                            </tr>
                          </thead>

                          <tbody>
                            {analysisOrderList.map((analysis) => {
                              return (
                                <tr key={analysis.nameService}>
                                  <td>{analysis.nameService}</td>
                                  <td>{analysis.nameBiomaterial}</td>
                                  <td>
                                    {analysis.price}{' '}
                                    {styles[0]?.TextIDsRepository.currency}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className='analysis-page__ordering__total-price__header'>
                      <span>Биоматериалы</span>

                      <span>{biomaterialCount} шт.</span>

                      <span>Адрес</span>

                      <span>проспект Мира, 23</span>
                    </div>
                  </>
                }
                classNames='analysis-page__ordering__total-price'
              />
            </div>
          </>
        </CoursePanel>
      ) : (
        <CoursePanel header={renderCreatedOrderHeader()} alwaysOpened={true}>
          <>
            <div
              className={clsx(
                'card-container',
                'analysis-page__ordering__container'
              )}
            >
              <div className='analysis-page__ordering__total-price__header'>
                <span className=''>Исследований</span>

                <span>
                  {analysisOrderList.length} шт.
                  <button
                    className='total-pay-panel__info-container__block-button'
                    onClick={() =>
                      setIsOpenDescriptionTable((prevState) => !prevState)
                    }
                  >
                    {!isOpenDescriptionTable ? <ChevronDown /> : <ChevronUp />}
                  </button>
                </span>

                {!isMobile && (
                  <>
                    <span>Лаборатория</span>
                    <span>Гемотест</span>
                  </>
                )}
              </div>

              {isOpenDescriptionTable && (
                <div
                  className={clsx(
                    'total-pay-panel__table-container',
                    'analysis-page__ordering__total-price__table'
                  )}
                >
                  <table>
                    <thead>
                      <tr>
                        <td>{styles[0].TextIDsRepository.formTableTHGoods}</td>
                        <td>Биоматериал</td>
                        <td className='total-pay-panel__table-container__text-right'>
                          Сумма
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {analysisOrderList.map((analysis) => {
                        return (
                          <tr key={analysis.nameService}>
                            <td>{analysis.nameService}</td>
                            <td>{analysis.nameBiomaterial}</td>
                            <td>
                              {analysis.price}{' '}
                              {styles[0]?.TextIDsRepository.currency}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className='analysis-page__ordering__total-price__header'>
                {isMobile && (
                  <>
                    <span>Лаборатория</span>
                    <span>Гемотест</span>
                  </>
                )}

                <span>Биоматериалы</span>

                <span>{biomaterialCount} шт.</span>

                <span>Адрес</span>

                <span>проспект Мира, 23</span>
              </div>

              <div className='analysis-page__ordering__detail'>
                <span className='analysis-page__ordering__detail__bold-text'>
                  Оплата
                </span>

                <span>В лаборатории</span>

                <span className='analysis-page__ordering__detail__bold-text'>
                  Получатель
                </span>

                <span>
                  {/*{profileData.customerName} {profileData.customerSurname}*/}
                </span>

                <span className='analysis-page__ordering__detail__bold-text'>
                  Сумма
                </span>

                <span className='analysis-page__ordering__detail__bold-text'>
                  {totalOrderPrice} {styles[0]?.TextIDsRepository.currency}
                </span>
              </div>
            </div>

            <div className='analysis-page__ordering__warning'>
              <span className='analysis-page__ordering__warning__title'>
                Сдайте анализы в течение 30 дней после оформления заказа (если
                не успеете заказ отменится).
              </span>
              <span className='analysis-page__ordering__warning__desc'>
                Назовите номер заказа и оплатите исследование наличными или
                картой на месте.
              </span>
            </div>

            <div
              className={clsx(
                'card-container',
                'analysis-page__ordering__status-panel'
              )}
            >
              <div className='analysis-page__ordering__status-panel__wrapper'>
                <Info />

                <span>
                  Вы можете отследить статус лабораторной диагностики и её
                  результаты в хронологии
                </span>
              </div>

              <Button
                title='Перейти в хронологию'
                click={() => navigate(RoutesEnum.PersonalCabinet)}
                classNames='analysis-page__ordering__status-panel__button'
              />
            </div>
          </>
        </CoursePanel>
      )}

      {isShowLoadFileScreen &&
        (cookies.CustomerID ||
          (!cookies.CustomerID && uploadFiles.length < 2)) && (
          <FileUpload
            setIsShowLoadFileScreen={setIsShowLoadFileScreen}
            dragEnterHandler={dragEnterHandler}
            dragLeaveHandler={dragLeaveHandler}
          />
        )}
    </>
  )
}

export default AnalysisContainer
