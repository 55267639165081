import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { CheckCircle, ChevronDown, HelpCircle, User } from 'react-feather'
import { motion } from 'framer-motion'

import { attachSpecialist } from '../../../redux/slices/specialistsSlice'
import Button from '../../UI/Button'
import PortalTooltip from '../../UI/PortalTooltip'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { pluralizeWords } from '../../../utils/pluralizeWords'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import EventCardBody from '../../PersonalCabinet/EventCardBody'
import { SpecialistResponse } from '../../../utils/axiosManager'

import './styles.scss'

type SpecialistCardProps = {
  specialist: SpecialistResponse
}

const SpecialistCard: React.FC<SpecialistCardProps> = ({ specialist }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [workExperience, setWorkExperience] = useState('')

  const currentSpecialist = useAppSelector(
    (state) => state.specialists.currentSpecialist
  )
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const dispatch = useAppDispatch()
  const { isMobile } = useMatchMedia()

  useEffect(() => {
    pluralizeWords(
      styles[0]?.TextIDsRepository.localeId,
      specialist.experience,
      styles[0]?.TextIDsRepository.yearDeclension.variants
    ).then((result) => {
      setWorkExperience(result)
    })
  }, [])

  return (
    <div
      className={clsx('card-container', 'specialist-card', {
        'specialist-card__current-specialist':
          currentSpecialist &&
          currentSpecialist.customerId === specialist.customerId,
      })}
    >
      <div
        className={clsx('card-header', 'specialist-card__header')}
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <motion.button
          className='card-header__button'
          animate={{ rotateX: isOpen ? 180 : 0 }}
        >
          <ChevronDown />
        </motion.button>

        <div className='specialist-card__photo'>
          {specialist.pathLink ? (
            <img
              src={specialist.pathLink}
              alt=''
              className='specialist-card__photo__specialist'
            />
          ) : (
            <div className='specialist-card__photo__specialist'>
              <User className='specialist-card__photo__specialist__empty' />
            </div>
          )}

          {currentSpecialist &&
            currentSpecialist.customerId === specialist.customerId &&
            (styles[0]?.TextIDsRepository.assets.statusIcon ? (
              <img
                src={styles[0].TextIDsRepository.assets.statusIcon}
                alt=''
                className='specialist-card__photo__active'
              />
            ) : (
              <CheckCircle className='specialist-card__photo__active' />
            ))}
        </div>

        <div className='specialist-card__name-container'>
          <span className='specialist-card__name-container__first-name'>
            {specialist.customerSurname}
          </span>

          <span className='specialist-card__name-container__last-name'>
            {specialist.customerName} {specialist.customerPatronymic}
          </span>
        </div>

        <div className='specialist-card__jobs-container'>
          {!isMobile && (
            <div className='specialist-card__jobs'>
              {specialist.customerInfo?.specializations?.map((spec) => (
                <span key={spec}>{spec}</span>
              ))}
            </div>
          )}

          <div className='specialist-card__city-info'>
            {!isMobile && (
              <span className='specialist-card__city-info__title'>
                {specialist.customerCity}
              </span>
            )}

            <span className='specialist-card__city-info__experience'>
              {`${styles[0]?.TextIDsRepository.specialistPageWorkExperience}: `}

              {isMobile && <br />}

              {`${workExperience}`}
            </span>
          </div>
        </div>
      </div>

      {isMobile && (
        <div className='specialist-card__work-skills'>
          <div className='specialist-card__work-skills__content'>
            <span
              className={clsx('specialist-card__work-skills__content__jobs', {
                'specialist-card__work-skills__content__jobs-one-line': !isOpen,
              })}
            >
              {specialist.customerInfo?.specializations?.map((spec) => (
                <span key={spec}>{spec}</span>
              ))}
            </span>

            <span className='specialist-card__work-skills__content__city'>
              {specialist.customerCity}
            </span>
          </div>
        </div>
      )}

      <EventCardBody
        isOpen={isOpen}
        classNamePadding='specialist-card__body-container'
      >
        <div className='specialist-card__body'>
          {specialist.customerInfo && (
            <span className='specialist-card__body__description'>
              {specialist.customerInfo.description}
            </span>
          )}

          {!currentSpecialist && (
            <div className='specialist-card__body__attach'>
              <Button
                title={styles[0].TextIDsRepository.specialistPageAttachButton}
                click={() => dispatch(attachSpecialist(specialist))}
              />

              <PortalTooltip
                text={
                  styles[0].TextIDsRepository.specialistPageSideBarEmptyDesc
                }
                classNames='specialist-card__body__attach__help-container'
              >
                <div className='specialist-card__body__attach__help-icon'>
                  <HelpCircle />
                </div>
              </PortalTooltip>
            </div>
          )}

          {specialist.customerInfo && (
            <div className='specialist-card__body__view-profile'>
              <span className='specialist-card__body__view-profile__title'>
                {isMobile
                  ? `${styles[0].TextIDsRepository.specialistPageProfileTitle}: `
                  : `${styles[0].TextIDsRepository.specialistPageViewProfile}: `}
              </span>

              <div className='specialist-card__body__view-profile__links-container'>
                {specialist.customerInfo.telegram && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.greyTelegramIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.telegram}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.instagram && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.instagramIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.instagram}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.skype && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={
                        styles[0].TextIDsRepository.assets.bestFileSharingIcon
                      }
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.skype}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.email && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.mailIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.email}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.vkontakte && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.vkIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.vkontakte}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.whatsapp && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.whatsAppIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.whatsapp}
                    </span>
                  </div>
                )}

                {specialist.customerInfo.facebook && (
                  <div className='specialist-card__body__view-profile__link-container'>
                    <img
                      src={styles[0].TextIDsRepository.assets.facebookIcon}
                      alt=''
                    />
                    <span className='specialist-card__body__view-profile__link'>
                      {specialist.customerInfo.facebook}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </EventCardBody>
    </div>
  )
}

export default SpecialistCard
