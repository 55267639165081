import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import InputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import { motion } from 'framer-motion'

import {
  updateCustomerCountry,
  updateCustomerPhoneAndID,
} from '../../redux/slices/authSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  getCustomerCountry,
  getCustomerValidatePhone,
} from '../../utils/axiosManager'
import Button from '../UI/Button'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'

import './styles.scss'

const Auth: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const { customerCountry } = useAppSelector((state) => state.auth)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')
  const isCabinetAuthPathName = useDefineCurrentPathName('/cabinet/auth')
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )

  useEffect(() => {
    getCustomerCountry().then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(updateCustomerCountry(data))
    })
  }, [])

  const close = () => {
    if (isCabinetPathName) {
      navigate(RoutesEnum.PersonalCabinet)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis)
      return
    }

    navigate(RoutesEnum.Main)
  }

  const checkPhoneIsFilled = () => {
    return isNaN(Number(phoneNumber.slice(-1)))
  }

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const processedPhoneNumber = event.target.value.substring(1)
    setPhoneNumber(processedPhoneNumber)
  }

  const getPhoneCode = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    getCustomerValidatePhone(phoneNumber).then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(updateCustomerPhoneAndID({ ...data, phoneNumber }))

      if (isCabinetAuthPathName) {
        if (
          !data.CustomerValidatePhone.CustomerID ||
          !data.CustomerValidatePhone.NutritionistID
        ) {
          navigate(RoutesEnum.PersonalCabinet + RoutesEnum.Register)
          return
        }

        if (data.CustomerValidatePhone.NutritionistID) {
          navigate(RoutesEnum.PersonalCabinet, {
            state: {
              ...location.state,
              activeToast: true,
            },
          })
          return
        }

        navigate(-1)
        return
      }

      if (isCabinetPathName) {
        navigate(RoutesEnum.Main + RoutesEnum.EnterCode, {
          state: {
            newPhoneNumber: phoneNumber,
          },
        })
        return
      }

      if (isAnalysisPathName) {
        navigate(RoutesEnum.Analysis + RoutesEnum.Login)
        return
      }

      navigate(RoutesEnum.Login)
    })
  }

  return (
    <motion.div
      className='modal-auth'
      role='button'
      tabIndex={0}
      onClick={close}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
    >
      <motion.div
        className='modal-auth__login-container'
        role='presentation'
        onClick={(event) => event.stopPropagation()}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1, transition: { duration: 0.2 } }}
      >
        <img
          className='modal-auth__login-container__logo'
          src={styles[0]?.PartnerLogo}
          alt=''
        />

        <span className='modal-auth__login-container__text'>
          {isCabinetPathName
            ? styles[0]?.TextIDsRepository.modalLoginTitle2
            : styles[0]?.TextIDsRepository.modalLoginTitle1}
        </span>

        <div className='modal-auth__login-container__phone-number-container'>
          <img
            className='modal-auth__login-container__phone-number-container__logo'
            src={customerCountry.countryFlag}
            alt=''
          />

          <InputMask
            className='modal-auth__login-container__phone-number-container__input'
            inputMode='tel'
            mask={`+${customerCountry.callingCode}9999999999`}
            maskPlaceholder='_'
            placeholder={`+${customerCountry.callingCode} _ _ _ _ _ _ _ _ _ _`}
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
        </div>

        <Button
          disabled={checkPhoneIsFilled() || !phoneNumber.length}
          title={styles[0]?.TextIDsRepository.modalLoginGetCode}
          click={getPhoneCode}
        />

        <button
          className='modal-auth__login-container__close-btn'
          onClick={close}
        >
          <X />
        </button>
      </motion.div>
    </motion.div>
  )
}

export default Auth
