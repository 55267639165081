import React from 'react'

import './styles.scss'

type RadioButtonProps = {
  label: string
  checked: boolean
  onChange: () => void
}
const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <label className='radio-button-label'>
      <input
        className='radio-button'
        type='radio'
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  )
}

export default RadioButton
