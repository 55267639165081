import React, { useState } from 'react'
import {
  ChevronDown,
  ChevronUp,
  FilePlus,
  FileText,
  LogIn,
  MoreHorizontal,
  Users,
} from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import clsx from 'clsx'

import { RoutesEnum } from '../../../enums/routes'
import { useAppSelector } from '../../../redux/hook'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { useDefineCurrentPathName } from '../../../hooks/useDefineCurrentPathName'

import './styles.scss'

type BottomContainerProps = {
  isOpenPanel: boolean
}

const BottomContainer: React.FC<BottomContainerProps> = ({ isOpenPanel }) => {
  const [isOpenDocs, setIsOpenDocs] = useState(false)
  const [isOpenBottomInTablet, setIsOpenBottomInTablet] = useState(false)

  const profile = useAppSelector((state) => state.profileSlice)
  const { styles } = useAppSelector((state) => state.partnerInterface)

  const navigate = useNavigate()
  const { isMobile, isTablet, isDesktop } = useMatchMedia()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )
  const isSpecialistsPathName = useDefineCurrentPathName(
    `${RoutesEnum.Specialists}/*`
  )

  const handleOpenDocs = () => {
    setIsOpenDocs((prevState) => !prevState)
  }

  const animateTransition = {
    type: 'spring',
    stiffness: 5000,
    damping: 100,
    mass: 0.2,
  }

  return (
    <div
      className={clsx({
        'bottom-container': isOpenPanel,
        'bottom-container-minimized': !isOpenPanel,
        'bottom-container-mobile': isMobile && isOpenPanel,
      })}
    >
      {(isTablet || isMobile) && isOpenPanel && !isOpenBottomInTablet ? (
        <div
          className='bottom-container__dots'
          role='button'
          tabIndex={0}
          onClick={() => setIsOpenBottomInTablet(true)}
        >
          <MoreHorizontal />
        </div>
      ) : (
        <>
          {(isDesktop && isOpenPanel) ||
          ((isTablet || isMobile) && isOpenPanel && isOpenBottomInTablet) ? (
            <>
              {styles[0]?.PartnerMenu.hasOwnProperty('analysisPage') && (
                <motion.button
                  className={clsx('bottom-container__service-button', {
                    'bottom-container__service-button__active':
                      isAnalysisPathName,
                  })}
                  onClick={() =>
                    navigate(RoutesEnum.Analysis, {
                      state: { activeCatalogItem: -1 },
                    })
                  }
                  whileHover={{ scale: 1.02 }}
                >
                  <FilePlus />
                  <span>{styles[0]?.TextIDsRepository.analysisTitle}</span>
                </motion.button>
              )}

              <button
                className={clsx('bottom-container__service-button', {
                  'bottom-container__service-button__active':
                    isSpecialistsPathName,
                })}
                onClick={() => {
                  if (profile.customerID) {
                    navigate(RoutesEnum.Specialists)
                  } else {
                    navigate(RoutesEnum.Auth)
                  }
                }}
              >
                <Users />
                <span>{styles[0]?.PartnerMenu.menuItem02.title}</span>
              </button>

              <button
                className='bottom-container__service-button'
                onClick={handleOpenDocs}
              >
                <FileText />
                <span>
                  {styles[0]?.PartnerMenu.menuItem03.title}
                  {isOpenDocs ? <ChevronUp /> : <ChevronDown />}
                </span>
              </button>
            </>
          ) : null}

          {isOpenDocs && isOpenPanel && (
            <motion.div
              className='bottom-container__docs'
              transition={animateTransition}
            >
              <button className='bottom-container__service-button'>
                <span>
                  {
                    styles[0]?.PartnerMenu.menuItem03.children.menuSubItem01
                      .title
                  }
                </span>
              </button>

              <button className='bottom-container__service-button'>
                <span>
                  {
                    styles[0]?.PartnerMenu.menuItem03.children.menuSubItem03
                      .title
                  }
                </span>
              </button>

              <button className='bottom-container__service-button'>
                <span>
                  {
                    styles[0]?.PartnerMenu.menuItem03.children.menuSubItem02
                      .title
                  }
                </span>
              </button>
            </motion.div>
          )}

          {!profile.customerName.length ? (
            <Link
              className={clsx('bottom-container__button', {
                'bottom-container__button-opened': isOpenPanel,
              })}
              to={
                isAnalysisPathName
                  ? RoutesEnum.Analysis + RoutesEnum.Auth
                  : RoutesEnum.Auth
              }
            >
              <LogIn />

              {isOpenPanel ? (
                <span>
                  {styles[0]?.TextIDsRepository.sidebarMenuFooterLabel}
                </span>
              ) : null}
            </Link>
          ) : (
            <button
              className={clsx('bottom-container__profile-button', {
                'bottom-container__profile-button__active': isCabinetPathName,
                'bottom-container__profile-button__collapsed': !isOpenPanel,
              })}
              onClick={() => {
                navigate(RoutesEnum.PersonalCabinet, {
                  state: { activeCatalogItem: -1 },
                })
              }}
            >
              <span
                className={clsx(
                  'bottom-container__profile-button__short-name',
                  {
                    'bottom-container__profile-button__short-name__active':
                      isCabinetPathName,
                  }
                )}
              >
                {profile.customerName[0]}
                {profile.customerSurname[0]}
              </span>

              {isOpenPanel && (
                <span className='bottom-container__profile-button__full-name'>
                  {profile.customerName} {profile.customerSurname}
                </span>
              )}
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default BottomContainer
