import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import './styles.scss'

type ButtonProps = {
  title: string | React.ReactNode
  click?: (event: React.MouseEvent<HTMLElement>) => void
  children?: React.ReactNode
  classNames?: string
  disabled?: boolean
  type?: HTMLButtonElement['type']
}

const Button: React.FC<ButtonProps> = ({
  title,
  click,
  children,
  classNames,
  type,
  disabled = false,
}) => {
  return (
    <motion.button
      className={clsx('button', classNames)}
      onClick={(event) => click && click(event)}
      disabled={disabled}
      type={type}
      whileTap={{ scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } }}
    >
      {title}
      {children}
    </motion.button>
  )
}

export default Button
