import React, { useEffect, useMemo, useState } from 'react'
import { ChevronDown, ChevronUp, HelpCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { AxiosError } from 'axios'

import Button from '../UI/Button'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { SupplementInCourse } from '../../redux/slices/courseSlice'
import CoursePanelDropdownMenu from '../CoursePanel/CoursePanelDropdownMenu'
import { CurrentTooltip, currentTooltipInitialState } from '../UI/Tooltip'
import PortalTooltip from '../UI/PortalTooltip'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { createOrder } from '../../utils/axiosManager'
import { CookieEnum } from '../../enums/cookie'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'

import './styles.scss'

type TotalPayPanelProps = {
  isLoadingCourseUpdated: boolean
}

const TotalPayPanel: React.FC<TotalPayPanelProps> = ({
  isLoadingCourseUpdated,
}) => {
  const [isOpenDescriptionTable, setIsOpenDescriptionTable] = useState(false)
  const [supplementsPrice, setSupplementsPrice] = useState(0)
  const [pricePerPortion, setPricePerPortion] = useState(0)
  const { course } = useAppSelector((state) => state.course)

  useEffect(() => {
    if (!isLoadingCourseUpdated) {
      countAllSupplementsPrice()
    }
  }, [isLoadingCourseUpdated])

  useEffect(() => {
    calculatePricePerPortion()
  }, [course.filteredByTime, supplementsPrice])

  const handleDescriptionTable = () => {
    setIsOpenDescriptionTable((prevState) => !prevState)
  }

  const calculateDosageFromRecipes = (supplement: SupplementInCourse) => {
    return supplement.recipes.reduce(
      (acc, currentValue) =>
        acc +
        currentValue.recipeDosage.reduce(
          (accDosage, currentValueDosage) =>
            accDosage + Number(currentValueDosage.dosage[0]),
          0
        ),
      0
    )
  }

  const calculateSupplementCount = (supplement: SupplementInCourse) => {
    const countOfDosages = calculateDosageFromRecipes(supplement)

    if (
      supplement.recipes.length === 1 &&
      supplement.recipes[0].id === 'daily'
    ) {
      return countOfDosages * course.duration * 7
    } else {
      return countOfDosages * course.duration
    }
  }

  const calculateUniqueSupplementCount = () => {
    if (!course.supplementsList.length) {
      return 0
    }

    return course.supplementsList
      .map((supplement) => {
        return supplement.recipes.map((recipe) => {
          return recipe.id === 'daily'
            ? supplement.recipes.length * 7
            : supplement.recipes.length
        })
      })
      .flat()
      .reduce((acc, value) => {
        return acc + value
      }, 0)
  }

  const calculateSupplementPrice = (supplement: SupplementInCourse) => {
    const countOfPills = calculateSupplementCount(supplement)

    return {
      countOfPills,
      supplementPrice: countOfPills * supplement.supplement.CurrentPrices,
    }
  }

  const calculatePriceForSupplement = (supplement: SupplementInCourse) => {
    const { supplementPrice } = calculateSupplementPrice(supplement)
    return supplementPrice
  }

  const calculateCoursePrice = () => {
    return (
      supplementsPrice +
      course.productCostServicePrice +
      course.variablePackageServicePrice
    )
  }

  const calculatePricePerPortion = () => {
    const count = calculateUniqueSupplementCount()
    const portion = Number((calculateCoursePrice() / count).toFixed(2))

    setPricePerPortion(!isFinite(portion) ? 0 : portion)
  }

  const countAllSupplementsPrice = () => {
    const count = course.supplementsList.reduce((acc, currentValue) => {
      const { supplementPrice } = calculateSupplementPrice(currentValue)
      return acc + supplementPrice
    }, 0)

    setSupplementsPrice(count)
  }

  return (
    <CoursePanelDropdownMenu
      Header={TotalPayPanelHeader}
      Body={TotalPayPanelBody}
      headerProps={{ pricePerPortion, calculateCoursePrice }}
      bodyProps={{
        supplementsPrice,
        isOpenDescriptionTable,
        handleDescriptionTable,
        calculateDosageFromRecipes,
        calculateSupplementCount,
        calculatePriceForSupplement,
      }}
      classNames='total-pay-panel'
    />
  )
}

const TotalPayPanelHeader: React.FC<{
  pricePerPortion: number
  calculateCoursePrice: () => number
}> = ({ pricePerPortion, calculateCoursePrice }) => {
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const { deliverySum } = useAppSelector((state) => state.shippingPayment)
  const order = useAppSelector((state) => state.order)
  const { course } = useAppSelector((state) => state.course)
  const profileData = useAppSelector((state) => state.profileSlice)
  const totalPay = useMemo(() => {
    return calculateCoursePrice() + deliverySum
  }, [deliverySum, pricePerPortion])
  const navigate = useNavigate()
  const [cookie, , removeToken] = useCookies([
    CookieEnum.CustomerID,
    CookieEnum.SupplementCourse,
  ])
  const dispatch = useAppDispatch()

  const makeOrder = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    if (!cookie.CustomerID) {
      navigate(RoutesEnum.Auth)
      return
    }

    createOrder(profileData.customerID, order).then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      navigate(RoutesEnum.PersonalCabinet)
      removeToken(CookieEnum.SupplementCourse)
    })
  }
  const { isMobile } = useMatchMedia()

  return (
    <>
      {isMobile ? (
        <>
          <div className='total-pay__header__title'>
            <span className='total-pay-main-text'>{`${
              styles[0]?.TextIDsRepository.myCourseTotalPayable
            }: ${calculateCoursePrice().toFixed(2)} ${
              styles[0]?.TextIDsRepository.currency
            }`}</span>

            <span className='total-pay-second-text'>
              {pricePerPortion} {styles[0]?.TextIDsRepository.currency}/
              {styles[0]?.TextIDsRepository.myCoursePortion}
            </span>
          </div>

          <Button
            title={styles[0]?.TextIDsRepository.myCourseOrder}
            click={makeOrder}
            classNames='total-pay-order-button'
            disabled={!course.supplementsList.length}
          />
        </>
      ) : (
        <>
          <span className='total-pay-main-text'>{`${
            styles[0]?.TextIDsRepository.myCourseTotalPayable
          }: ${totalPay.toFixed(2)} ${
            styles[0]?.TextIDsRepository.currency
          }`}</span>

          <span className='total-pay-second-text'>
            {pricePerPortion} {styles[0]?.TextIDsRepository.currency}/
            {styles[0]?.TextIDsRepository.myCoursePortion}
          </span>

          <Button
            title={styles[0]?.TextIDsRepository.myCourseOrder}
            click={makeOrder}
            classNames='total-pay-order-button'
            disabled={!course.supplementsList.length}
          />
        </>
      )}
    </>
  )
}

const TotalPayPanelBody: React.FC<{
  supplementsPrice: number
  isOpenDescriptionTable: boolean
  handleDescriptionTable: () => void
  calculateDosageFromRecipes: (supplement: SupplementInCourse) => number
  calculateSupplementCount: (supplement: SupplementInCourse) => number
  calculatePriceForSupplement: (supplement: SupplementInCourse) => number
}> = ({
  supplementsPrice,
  isOpenDescriptionTable,
  handleDescriptionTable,
  calculateDosageFromRecipes,
  calculateSupplementCount,
  calculatePriceForSupplement,
}) => {
  const [currentTooltip, setCurrentTooltip] = useState<CurrentTooltip>(
    currentTooltipInitialState
  )
  const { course } = useAppSelector((state) => state.course)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const { deliverySum } = useAppSelector((state) => state.shippingPayment)
  const { isMobile } = useMatchMedia()

  const handleOnMouseEnter = (text: string) => {
    setCurrentTooltip({
      isShow: true,
      text,
    })
  }

  const handleOnMouseLeave = () => {
    setCurrentTooltip(currentTooltipInitialState)
  }

  return (
    <>
      <div className='total-pay-panel__info-container__header-block'>
        <span className='total-pay-panel__info-container__block-title'>
          {styles[0]?.TextIDsRepository.myCourseSupplements}
        </span>
        <span className='total-pay-panel__info-container__block-price'>
          {`${supplementsPrice.toFixed(2)} ${
            styles[0]?.TextIDsRepository.currency
          }`}
        </span>
        {!isMobile && (
          <button
            className='total-pay-panel__info-container__block-button'
            onClick={handleDescriptionTable}
          >
            {!isOpenDescriptionTable ? <ChevronDown /> : <ChevronUp />}
          </button>
        )}
      </div>

      {isOpenDescriptionTable ? (
        <div className='total-pay-panel__table-container'>
          <table>
            <thead>
              <tr>
                <td>{styles[0].TextIDsRepository.formTableTHGoods}</td>
                <td>
                  {styles[0].TextIDsRepository.myCourseHowToUse.slice(0, -1)}
                </td>
                <td className='total-pay-panel__table-container__text-right'>
                  {styles[0].TextIDsRepository.myCourseDosage}
                </td>
                <td className='total-pay-panel__table-container__text-right'>
                  {styles[0]?.TextIDsRepository.myCourseSupplementPerWeek}
                </td>
                <td className='total-pay-panel__table-container__text-right'>
                  {`${styles[0].TextIDsRepository.formTableTHPrice} ${styles[0]?.TextIDsRepository.currency}`}
                </td>
                <td className='total-pay-panel__table-container__text-right'>
                  {
                    styles[0].TextIDsRepository
                      .modalSupplementCardSuppFactsActiveComponentsWeigth
                  }
                </td>
                <td className='total-pay-panel__table-container__text-right'>
                  {styles[0]?.TextIDsRepository.myCourseSupplementPrice}{' '}
                  {styles[0]?.TextIDsRepository.currency}
                </td>
              </tr>
            </thead>

            <tbody>
              {course.supplementsList.map((supplement) => {
                return (
                  <tr key={supplement.supplement.Article}>
                    <td>{supplement.supplement.GoodsCommercialName}</td>
                    <td>
                      {supplement.recipes[0].id === 'daily'
                        ? 'Ежедневно'
                        : 'Еженедельно'}
                    </td>
                    <td className='total-pay-panel__table-container__text-right'>
                      {calculateDosageFromRecipes(supplement)}
                    </td>
                    <td className='total-pay-panel__table-container__text-right'>
                      {course.duration}
                    </td>
                    <td className='total-pay-panel__table-container__text-right'>
                      {supplement.supplement.CurrentPrices}
                    </td>
                    <td className='total-pay-panel__table-container__text-right'>
                      {calculateSupplementCount(supplement)}
                    </td>
                    <td className='total-pay-panel__table-container__text-right'>
                      {calculatePriceForSupplement(supplement).toFixed(2)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : null}

      <div className='total-pay-panel__info-container__block-container'>
        <div className='total-pay-panel__info-container__block'>
          <span className='total-pay-panel__info-container__block-title'>
            {styles[0].TextIDsRepository.myCourseDelivery}
          </span>
          <span className='total-pay-panel__info-container__block-price'>
            {deliverySum} {styles[0]?.TextIDsRepository.currency}
          </span>

          <PortalTooltip text={currentTooltip.text}>
            <button
              id='total-pay-panel__info-container__block-button'
              className='total-pay-panel__info-container__block-button'
              onMouseEnter={() => {
                handleOnMouseEnter(
                  styles[0].TextIDsRepository.myCourseDeliveryTooltip
                )
              }}
              onMouseLeave={handleOnMouseLeave}
            >
              <HelpCircle className='total-pay-panel__info-container__image' />
            </button>
          </PortalTooltip>
        </div>

        <div className='total-pay-panel__info-container__block'>
          <span className='total-pay-panel__info-container__block-title'>
            {styles[0]?.TextIDsRepository.myCourseAdditionalPackaging}
          </span>
          <span className='total-pay-panel__info-container__block-price'>
            {`${course.variablePackageServicePrice} ${styles[0]?.TextIDsRepository.currency}`}
          </span>

          <PortalTooltip text={currentTooltip.text}>
            <button
              id='total-pay-panel__info-container__block-button'
              className='total-pay-panel__info-container__block-button'
              onMouseEnter={() => {
                handleOnMouseEnter(
                  styles[0].TextIDsRepository.myCourseAdditionalPackagingTooltip
                )
              }}
              onMouseLeave={handleOnMouseLeave}
            >
              <HelpCircle className='total-pay-panel__info-container__image' />
            </button>
          </PortalTooltip>
        </div>

        <div className='total-pay-panel__info-container__block'>
          <span className='total-pay-panel__info-container__block-title'>
            {styles[0]?.TextIDsRepository.myCourseServiceFee}
          </span>
          <span className='total-pay-panel__info-container__block-price'>
            {`${course.productCostServicePrice} ${styles[0]?.TextIDsRepository.currency}`}
          </span>

          <PortalTooltip text={currentTooltip.text}>
            <button
              id='total-pay-panel__info-container__block-button'
              className='total-pay-panel__info-container__block-button'
              onMouseEnter={() => {
                handleOnMouseEnter(
                  styles[0].TextIDsRepository.myCourseServiceFeeTooltip
                )
              }}
              onMouseLeave={handleOnMouseLeave}
            >
              <HelpCircle className='total-pay-panel__info-container__image' />
            </button>
          </PortalTooltip>
        </div>

        <div className='total-pay-panel__info-container__block'>
          <span className='total-pay-panel__info-container__block-title'>
            {styles[0]?.TextIDsRepository.myCoursePersonalization}
          </span>
          <span className='total-pay-panel__info-container__block-price'>
            0 {styles[0]?.TextIDsRepository.currency}
          </span>

          <PortalTooltip text={currentTooltip.text}>
            <button
              id='total-pay-panel__info-container__block-button'
              className='total-pay-panel__info-container__block-button'
              onMouseEnter={() => {
                handleOnMouseEnter(
                  styles[0].TextIDsRepository.myCoursePersonalizationTooltip
                )
              }}
              onMouseLeave={handleOnMouseLeave}
            >
              <HelpCircle className='total-pay-panel__info-container__image' />
            </button>
          </PortalTooltip>
        </div>
      </div>
    </>
  )
}

export default TotalPayPanel
