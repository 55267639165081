import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CustomerCountryResponseType,
  CustomerValidatePhoneResponseType,
} from '../../utils/axiosManager'

type Auth = {
  customerID: string | null
  customerPhone: string
  customerCountry: CustomerCountry
}

type CustomerCountry = {
  callingCode: string
  countryFlag: string
}

const initialState: Auth = {
  customerID: '',
  customerPhone: '',
  customerCountry: {
    callingCode: '',
    countryFlag: '',
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCustomerCountry(
      state,
      action: PayloadAction<CustomerCountryResponseType>
    ) {
      return {
        ...state,
        customerCountry: {
          callingCode: action.payload.CustomerCountry.CallingCode,
          countryFlag: action.payload.CustomerCountry.CountryFlag,
        },
      }
    },
    updateCustomerPhoneAndID(
      state,
      action: PayloadAction<
        CustomerValidatePhoneResponseType & { phoneNumber: string }
      >
    ) {
      return {
        ...state,
        customerID: action.payload.CustomerValidatePhone.CustomerID,
        customerPhone: action.payload.phoneNumber,
      }
    },
  },
})

export const { updateCustomerCountry, updateCustomerPhoneAndID } =
  authSlice.actions

export default authSlice.reducer
