import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CustomerDataResponseType,
  NutritionistClient,
  NutritionistClientTag,
} from '../../utils/axiosManager'
import { RegisterFormFields } from '../../components/Register/Register'
import { FilteredByTimeType, SupplementInCourse } from './courseSlice'
import { SpecialistEntity } from './specialistsSlice'

export type CustomerProfile = Omit<
  SpecialistEntity,
  'id' | 'firstName' | 'lastName' | 'surName' | 'city'
> & {
  customerID: string
  customerSurname: string
  customerName: string
  customerPatronymic: string
  customerCityID: string
  customerCityFullName: string
  customerDateOfBirth: string
  customerEmail: string
  customerPhone: number
  nutritionist: boolean | null
  earningID: number | null
  selectedDeliveryCourseOrderNumberPlatform: string
  selectedDeliveryCourseReceptionDuration: number | null
  sortedBySupplementSelectedDeliveryCourse: SupplementInCourse[]
  sortedByTimeSelectedDeliveryCourse: FilteredByTimeType
  clients: NutritionistClient[]
  tags: NutritionistClientTag[]
}

const initialState: CustomerProfile = {
  customerID: '',
  customerSurname: '',
  customerName: '',
  customerPatronymic: '',
  customerCityID: '',
  customerCityFullName: '',
  customerDateOfBirth: '',
  customerEmail: '',
  customerPhone: 0,
  nutritionist: null,
  earningID: null,
  selectedDeliveryCourseOrderNumberPlatform: '',
  selectedDeliveryCourseReceptionDuration: null,
  sortedBySupplementSelectedDeliveryCourse: [],
  sortedByTimeSelectedDeliveryCourse: {},
  clients: [],
  tags: [],
  description: '',
  photo: '',
  specializations: [],
  telegram: null,
  instagram: null,
  skype: null,
  email: null,
  vkontakte: null,
  whatsapp: null,
  facebook: null,
  factOne: '',
  factTwo: '',
  factThree: '',
  banner: false,
  yearsExperience: 0,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfileDataFromServer(
      state,
      action: PayloadAction<CustomerDataResponseType>
    ) {
      return {
        ...state,
        customerSurname: action.payload.CustomerData.CustomerSurname,
        customerName: action.payload.CustomerData.CustomerName,
        customerPatronymic: action.payload.CustomerData.CustomerPatronymic,
        customerCityID: action.payload.CustomerData.CustomerCityID,
        customerCityFullName: action.payload.CustomerData.CustomerCityFullName,
        customerDateOfBirth: action.payload.CustomerData.CustomerDateOfBirth,
        customerEmail: action.payload.CustomerData.CustomerEmail,
        customerPhone: action.payload.CustomerData.CustomerPhone,
        nutritionist: action.payload.CustomerData.Nutritionist,
        earningID: action.payload.CustomerData.EarningID,
      }
    },
    updateProfileData(state, action: PayloadAction<RegisterFormFields>) {
      return {
        ...state,
        customerSurname: action.payload.CustomerSurname,
        customerName: action.payload.CustomerName,
        customerPatronymic: action.payload.CustomerPatronymic,
        customerCityID: action.payload.CustomerCityID,
        customerDateOfBirth: action.payload.CustomerDateOfBirth,
        customerEmail: action.payload.CustomerEmail,
      }
    },
    updateOneClientTags(
      state,
      action: PayloadAction<{ clientId: string; tags: NutritionistClientTag[] }>
    ) {
      state.clients = state.clients.map((client) => {
        if (client.CustomerID === action.payload.clientId) {
          return {
            ...client,
            Tags: action.payload.tags,
          }
        }

        return client
      })
    },
    updateProfileCustomerField(
      state,
      action: PayloadAction<Partial<Record<keyof CustomerProfile, unknown>>>
    ) {
      const [key, value] = Object.entries(action.payload).flat()

      return {
        ...state,
        [key as string]: value,
      }
    },
  },
})

export const {
  updateProfileDataFromServer,
  updateProfileData,
  updateOneClientTags,
  updateProfileCustomerField,
} = profileSlice.actions

export default profileSlice.reducer
