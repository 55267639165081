import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import RadioButton from '../UI/RadioButton/RadioButton'
import { DeliveryToPoint } from '../../utils/axiosManager'
import {
  DeliveryOptionType,
  HeaderOptionsText,
} from '../ShippingPayment/ShippingPayment'
import Map from '../Map'

import './styles.scss'
import { updateOrderField } from '../../redux/slices/orderSlice'

type DeliveryProps = {
  activeDeliveryOption: string
  handleDeliveryOption: (option: DeliveryOptionType) => void
  locationsList: DeliveryToPoint[]
  setCurrentPaymentMethodText: (
    value: (prevState: HeaderOptionsText) => HeaderOptionsText
  ) => void
}

const Delivery: React.FC<DeliveryProps> = ({
  activeDeliveryOption,
  handleDeliveryOption,
  locationsList,
  setCurrentPaymentMethodText,
}) => {
  const [isOpenComment, setIsOpenComment] = useState(false)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()

  const handleComment = () => {
    setIsOpenComment((prevState) => !prevState)
  }

  const deliveryOptionOnChange = (
    option: DeliveryOptionType,
    label: string
  ) => {
    handleDeliveryOption(option)
    setCurrentPaymentMethodText((prevState) => ({
      ...prevState,
      delivery: label,
    }))
  }

  const handleOrderComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(updateOrderField({ DeliveryComment: event.target.value }))
  }

  return (
    <div className='delivery-container'>
      <div className='delivery-container__map-controls'>
        <RadioButton
          label={styles[0]?.TextIDsRepository.myCourseDeliveryNPayCourier}
          checked={activeDeliveryOption === 'courier'}
          onChange={() =>
            deliveryOptionOnChange(
              'courier',
              styles[0]?.TextIDsRepository.myCourseDeliveryNPayCourier
            )
          }
        />

        <RadioButton
          label={styles[0]?.TextIDsRepository.myCourseDeliveryNPayPVZ}
          checked={activeDeliveryOption === 'point'}
          onChange={() =>
            deliveryOptionOnChange(
              'point',
              styles[0]?.TextIDsRepository.myCourseDeliveryNPayPVZ
            )
          }
        />

        <RadioButton
          label={styles[0]?.TextIDsRepository.myCourseDeliveryNPayPostamat}
          checked={activeDeliveryOption === 'mailStation'}
          onChange={() =>
            deliveryOptionOnChange(
              'mailStation',
              styles[0]?.TextIDsRepository.myCourseDeliveryNPayPostamat
            )
          }
        />
      </div>

      {locationsList && <Map locationsList={locationsList} />}

      <div className='delivery-container__comment'>
        <button
          className='delivery-container__comment__title'
          onClick={handleComment}
        >
          <span>{styles[0]?.TextIDsRepository.myCourseDeliveryComment}</span>

          {isOpenComment ? <ChevronUp /> : <ChevronDown />}
        </button>

        {isOpenComment && (
          <textarea
            className='delivery-container__comment__text'
            onChange={handleOrderComment}
          />
        )}
      </div>
    </div>
  )
}

export default Delivery
