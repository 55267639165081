import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowLeft, X, RotateCw } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { useCookies } from 'react-cookie'

import { AxiosError } from 'axios'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  updateProfileCustomerField,
  updateProfileDataFromServer,
} from '../../redux/slices/profileSlice'
import {
  customerEditPhone,
  getCustomerData,
  getValidateEvent,
} from '../../utils/axiosManager'
import { CookieEnum } from '../../enums/cookie'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'

import './styles.scss'

const Login: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationCodeFromServer, setVerificationCodeFromServer] =
    useState('')
  const [currentVerificationCodeIndex, setCurrentVerificationCodeIndex] =
    useState(0)
  const [callTimer, setCallTimer] = useState(10)
  const [isRestartCall, setIsRestartCall] = useState(false)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const auth = useAppSelector((state) => state.auth)
  const profileData = useAppSelector((state) => state.profileSlice)
  const [, setCookies] = useCookies([CookieEnum.CustomerID])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isCabinetPathName = useDefineCurrentPathName(
    `${RoutesEnum.PersonalCabinet}/*`
  )
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )
  const inputNode = useRef<any>(null) // because types conflict

  const phoneNumber = useMemo(
    () => formatPhoneNumber(auth.customerPhone),
    [auth.customerPhone]
  )

  useEffect(() => {
    handleValidateEvent()

    const interval = handleInterval()

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const verificationCodeNumber = Number(verificationCode)
    const verificationCodeFromServerNumber = Number(verificationCodeFromServer)
    const customerID = auth.customerID

    if (inputNode) {
      inputNode.current?.focus()
    }

    if (!customerID) {
      navigate(RoutesEnum.Register)
      return
    }

    if (
      verificationCode.length &&
      verificationCodeNumber === verificationCodeFromServerNumber
    ) {
      if (isCabinetPathName) {
        const editPhoneRequest = {
          CustomerID: profileData.customerID,
          CustomerPhone: location.state.newPhoneNumber,
        }

        customerEditPhone(editPhoneRequest).then((data) => {
          if (data instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          dispatch(
            updateProfileCustomerField({
              customerPhone: location.state.newPhoneNumber,
            })
          )
        })

        navigate(RoutesEnum.PersonalCabinet)
        return
      }

      if (!customerID) {
        navigate(RoutesEnum.Register)
        return
      }

      if (isAnalysisPathName) {
        navigate(RoutesEnum.Analysis)
      } else {
        navigate(RoutesEnum.Main)
      }

      setCookies(CookieEnum.CustomerID, customerID)
      dispatch(updateProfileCustomerField({ customerID: customerID }))

      getCustomerData(customerID).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        if (data.CustomerData.CustomerName) {
          dispatch(updateProfileDataFromServer(data))
        }
      })
    } else if (
      verificationCode.length &&
      !isNaN(verificationCodeNumber) &&
      verificationCodeNumber !== verificationCodeFromServerNumber
    ) {
      setIsRestartCall(true)
    }
  }, [verificationCode])

  const close = () => {
    if (isCabinetPathName) {
      navigate(RoutesEnum.PersonalCabinet)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis)
      return
    }

    navigate(RoutesEnum.Main)
  }

  const back = () => {
    if (isCabinetPathName) {
      navigate(`${RoutesEnum.PersonalCabinet + RoutesEnum.ChangePhone}`)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis + RoutesEnum.Auth)
      return
    }

    navigate(RoutesEnum.Auth)
  }

  const handleValidateEvent = () => {
    getValidateEvent(auth.customerPhone).then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      setVerificationCodeFromServer(data.ValidateEvent.Code)
    })
  }

  const handleInterval = () => {
    const interval = setInterval(() => {
      setCallTimer((prevState) => {
        if (prevState === 1) {
          clearInterval(interval)
        }
        return prevState - 1
      })
    }, 1000)

    return interval
  }

  const restartCall = () => {
    const interval = handleInterval()
    setIsRestartCall(false)
    setVerificationCode('')
    setCurrentVerificationCodeIndex(0)
    setCallTimer(10)
    handleValidateEvent()
    clearInterval(interval)
  }

  return (
    <div className='modal-auth' role='button' tabIndex={0} onClick={close}>
      <div
        className={clsx('modal-auth__login-container', 'modal-enter-code')}
        role='presentation'
        onClick={(event) => event.stopPropagation()}
      >
        <button
          className='modal-auth__login-container__back-btn'
          onClick={back}
        >
          <ArrowLeft />
        </button>

        <span
          className={clsx(
            'modal-auth__login-container__text',
            'modal-enter-code__phone-number'
          )}
        >
          {phoneNumber}
        </span>

        {!isRestartCall && (
          <span className='modal-enter-code__info'>
            {styles[0]?.TextIDsRepository.modalLoginCofirmTooltipBeforeCounter}
            {callTimer}
            {styles[0]?.TextIDsRepository.modalLoginCofirmTooltipAfterCounter}
          </span>
        )}

        <div className='modal-enter-code__enter-code-container'>
          <InputMask
            className='modal-enter-code__enter-code-input'
            inputMode='numeric'
            mask={'9999'}
            maskPlaceholder='_'
            placeholder='____'
            value={verificationCode}
            onChange={(event) => {
              setVerificationCode(event.target.value)
              setCurrentVerificationCodeIndex(event.target.value.indexOf('_'))
            }}
            ref={inputNode}
          />

          {Array.from({ length: 4 }, (_, index) => (
            <div
              key={index}
              className={clsx('modal-enter-code__enter-code-number', {
                'modal-enter-code__enter-code-number-active':
                  currentVerificationCodeIndex === index,
                'modal-enter-code__enter-code-number-error': isRestartCall,
              })}
            >
              <span>{verificationCode[index]}</span>
            </div>
          ))}

          {isRestartCall && (
            <button
              className='modal-enter-code__restart-call-button'
              onClick={restartCall}
            >
              <RotateCw />
              {styles[0]?.TextIDsRepository.modalLogInWrongConfirmCallback}
            </button>
          )}
        </div>

        {isRestartCall && (
          <ErrorMessage
            message={
              styles[0]?.TextIDsRepository.modalLoginWrongCodeWarnMessage
            }
            classNames='modal-auth__login-container__error'
          />
        )}

        <button
          className='modal-auth__login-container__close-btn'
          onClick={close}
        >
          <X />
        </button>
      </div>
    </div>
  )
}

export default Login
