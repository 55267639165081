import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { SpecialistResponse } from '../../utils/axiosManager'

export type SpecialistEntity = {
  id: string
  firstName: string
  lastName: string
  surName: string
  city: string
  description: string
  photo: string
  specializations: string[]
  telegram: string | null
  instagram: string | null
  skype: string | null
  email: string | null
  vkontakte: string | null
  whatsapp: string | null
  facebook: string | null
  banner: boolean | null
  factOne: string | null
  factTwo: string | null
  factThree: string | null
  yearsExperience: number
}

type SpecialistsState = {
  currentSpecialist: SpecialistResponse | null
  list: SpecialistResponse[]
}

const initialState: SpecialistsState = {
  currentSpecialist: null,
  list: [],
}

const specialistsSlice = createSlice({
  name: 'specialists',
  initialState,
  reducers: {
    setSpecialistsList(state, action: PayloadAction<SpecialistResponse[]>) {
      state.list = action.payload
    },
    attachSpecialist(state, action: PayloadAction<SpecialistResponse>) {
      const currentSpecialistIndex = current(state.list).indexOf(action.payload)

      state.currentSpecialist = action.payload

      if (currentSpecialistIndex !== -1) {
        state.list.unshift(...state.list.splice(currentSpecialistIndex, 1))
      }
    },
  },
})

export const { attachSpecialist, setSpecialistsList } = specialistsSlice.actions

export default specialistsSlice.reducer
